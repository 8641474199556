import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

import { RouterLocation } from "types/types";
import DocumentationSection from "components/docs/DocumentationSection";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import LINKS, { MERGE_DASHBOARD, MERGE_PRICING_PAGE } from "components/docs/navigation/links";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { PricingInfoCard } from "components/docs/shared-components/NoticeCards";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import CodeBlock from "components/docs/shared-components/code-block/CodeBlock";

interface Props {
  location: RouterLocation;
}

const StyledListItem = styled.li`
  list-style-type: disc;
`;

const StyledB = styled.b`
  color: var(--blue40);
`;

const PassthroughAsyncPassthroughPage = ({ location }: Props) => {
  const title = "Async Passthrough";
  const description = "Learn how to create Passthrough Requests that may be sent asynchronously.";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} isBeta>
        <PricingInfoCard className="mb-9 mt-0">
          This feature is only available to customers on our <b>Professional</b> or{" "}
          <b>Enterprise</b> plans. View the{" "}
          <a href={MERGE_PRICING_PAGE} target="_blank" rel="noreferrer">
            {" "}
            Merge Plans
          </a>{" "}
          to learn more.
        </PricingInfoCard>
      </HeaderBar>
      {/* Overview */}
      <DocumentationSection title="Overview">
        <>
          <p>
            Third-party response times differ and can sometimes cause delays or timeout. With async
            Passthrough Requests, which allows you to retrieve the response at a later time, you no
            longer have to wait for a response before calling other requests or endpoints.
          </p>
          <p>
            If you have experienced delays or timeouts with normal Passthrough Requests we recommend
            switching to use Async Passthrough requests. Merge&apos;s normal Passthrough Requests
            endpoint will return a <b>408 status</b> if there is a timeout in getting the response,
            with an explanation to use Async Passthrough.
          </p>
          <p>
            Merge&apos;s Async Passthrough endpoints take the same request payload as{" "}
            <a href={LINKS.PASSTHROUGH_REQUEST_OVERVIEW.linkTo}>normal Passthrough Requests</a>, and
            return a <b>Passthrough Request receipt ID</b>, which can be used to get the data
            payload at a later time via <b>polling</b> or <b>webhook</b>. We recommend using the
            webhook since we rate limit the polling endpoint to ensure its availability for all
            Merge customers.
          </p>
        </>
      </DocumentationSection>

      {/* API Endpoint */}
      <hr className="my-9" />
      <DocumentationSection title="API endpoint">
        <p>
          Send POST requests to the URL below with the required body parameters to create an Async
          Passthrough Requests.
        </p>
        <CodeBlock
          text="https://api.merge.dev/api/{CATEGORY}/v1/async-passthrough"
          language="bash"
          hasLineNumbers={false}
          hideCopyButtonWithHeader
          hasInlineCopy
        />
        <p>
          Replace <code>CATEGORY</code> in the URL with <code>hris</code>, <code>ats</code>,
          <code>accounting</code>, <code>ticketing</code>, <code>crm</code>, <code>mktg</code>, or{" "}
          <code>filestorage</code> depending on the relevant category you&apos;re making an API
          request to.
        </p>
      </DocumentationSection>

      {/* Making an Async Passthrough Request */}
      <hr className="my-9" />
      <DocumentationSection title="Making an Async Passthrough Request">
        <ol>
          <li className="mb-4">
            Send a POST request to the API endpoint above, with the same payload as a normal
            Passthrough Request.
          </li>
          <li className="mb-4">
            Merge will respond with a <b>200 status</b> and an{" "}
            <code>async_passthrough_receipt_id</code> in the response body.
          </li>
          <li>
            There are two ways to get the data payload:
            <ul className="mt-4">
              <StyledListItem className="mb-2">
                (Recommended option) Set up a webhook emitter, which can be configured in the{" "}
                <a href={MERGE_DASHBOARD} target="_blank" rel="noreferrer">
                  dashboard
                </a>
                , to listen for the request from our server.{" "}
                <a href={LINKS.WEBHOOKS_OVERVIEW.linkTo} target="_blank" rel="noreferrer">
                  Learn how to set up a webhook.
                </a>
              </StyledListItem>
              <StyledListItem>
                Poll using the <StyledB>GET</StyledB>{" "}
                <b>{"/{category}/v1/async-passthrough/{async_passthrough_receipt_id} "}</b>
                endpoint. Polling requests are rate limited to <b>1 request per 10 seconds.</b>
              </StyledListItem>
            </ul>
          </li>
        </ol>
      </DocumentationSection>
      <CrossGuideLinks location={location} />
    </StaticPageContainer>
  );
};

export default PassthroughAsyncPassthroughPage;
