import React from "react";
import { StaticPageContainer } from "../../../components/docs/shared-components/PageContainers";
import DocsHelmet from "../../../components/docs/shared-components/DocsHelmet";
import HeaderBar from "../../../components/docs/layout/HeaderBar";
import styled from "styled-components";
import GlowButton from "components/generic/Button/GlowButton";
import { Link } from "gatsby";
import { palette } from "styles/theme";
import {
  FlexRow,
  LinkTitle,
  CrossGuideLink,
  ChevronRight,
} from "components/docs/navigation/CrossGuideLinks";
import { RouterLocation } from "types/types";
import DoubleWebhook from "../../../assets/images/docs/webhooks/DoubleWebhook.svg";
import DocumentationSection from "components/docs/DocumentationSection";
import { Text } from "@merge-api/merge-javascript-shared";

const StyledFlexRow = styled(FlexRow)`
  justify-content: flex-end;
`;

interface WebhooksOverviewProps {
  location: RouterLocation;
}

const DocumentationWebhooksOverviewPage = ({ location }: WebhooksOverviewProps) => {
  const title = "Webhooks";
  const description =
    "Use Merge's two types of webhooks to keep your application up to date in real-time.";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview">
        <p>
          Merge utilizes a double-webhook system to ensure your app&apos;s data is updated in real
          time.
        </p>
        <div className="flex justify-center">
          <DoubleWebhook className="rounded-lg" />
        </div>
      </DocumentationSection>

      <div className="flex justify-center gap-6 items-start self-stretch">
        <div className="bg-gray-100 rounded-[10px]  flex flex-[1_0_0] pl-5 pr-5 pt-4 pb-5 flex-col justify-between items-end  self-stretch">
          <div className="flex flex-col h-full self-stretch">
            <Text className="text-[14px] leading-[1.4] font-semibold mb-[.84375rem]">
              Merge webhooks
            </Text>
            <p className="text-[12px]">
              Configure Merge webhooks that send data payloads to your app.
            </p>
            <div className="flex flex-grow justify-end items-end">
              <div>
                <Link to="/basics/webhooks/merge-webhooks">
                  <GlowButton color={palette.black} showChevron borderRadius={6} text="View docs" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-[10px] bg-gray-100 flex flex-[1_0_0] pl-5 pr-5 pt-4 pb-5 flex-col justify-between items-end  self-stretch">
          <div className="flex flex-col h-full self-stretch">
            <Text className="text-[14px] leading-[1.4] font-semibold mb-[.84375rem]">
              Third-party webhooks
            </Text>
            <p className="text-[12px]">
              Configure webhooks in supported third parties that send data payloads to Merge.
            </p>
            <div className="flex flex-grow justify-end items-end">
              <Link to="/basics/webhooks/third-party-webhooks">
                <GlowButton color={palette.black} showChevron borderRadius={6} text="View docs" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-10 w-full mb-20 ml-auto mr-auto max-w-[1600px]">
        <StyledFlexRow>
          <div>
            <>
              <LinkTitle>Next</LinkTitle>
              <CrossGuideLink to="/basics/webhooks/merge-webhooks/">
                Merge To You
                <ChevronRight />
              </CrossGuideLink>
            </>
          </div>
        </StyledFlexRow>
      </div>
    </StaticPageContainer>
  );
};
export default DocumentationWebhooksOverviewPage;
