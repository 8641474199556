import CodeExample from "components/docs/code-examples/CodeExample";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import LINKS from "components/docs/navigation/links";
import NavigationScrollTracker from "components/docs/navigation/NavigationScrollTracker";
import NavigationScrollTrackerWithAnchor from "components/docs/navigation/NavigationScrollTrackerWithAnchor";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import CodeBlock from "components/docs/shared-components/code-block/CodeBlock";
import CodeExampleExplainer from "components/docs/shared-components/CodeExampleExplainer";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import ExpandableTable from "components/docs/shared-components/ExpandableTable";
import SectionDivider from "components/docs/shared-components/SectionDivider";
import { StaticFancyPageContainerWithTOC } from "components/docs/shared-components/StaticFancyPageContainer";
import { Link, PageProps } from "gatsby";
import React from "react";
import styled from "styled-components";

const CODE_FOLDER = "writes-troubleshooting/troubleshooting";

/**
 * Small width section with padding
 */
const Section = styled(SmallWidthSection).attrs({ className: "mt-9 mb-9" })``;

/**
 * Small width section with smaller padding above
 */
const ShortSection = styled(SmallWidthSection).attrs({ className: "mb-9" })`
  margin-top: 0px;
`;

/**
 * Creates a guide for Troubleshooting Writes
 */
const TroubleshootingWrites = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => (
  <StaticFancyPageContainerWithTOC
    tableOfContents={[
      {
        text: "Overview",
      },
      {
        text: "Warnings and errors",
        linkItems: [{ text: "Warnings" }, { text: "Errors" }, { text: "Properties" }],
      },
      {
        text: "Debug mode",
        linkItems: [{ text: "Usage" }],
      },
    ]}
  >
    <DocsHelmet
      title="Troubleshooting Merge Writes"
      description="Learn about the tools used to troubleshoot POST requests."
    />
    <SmallWidthSection>
      <NavigationScrollTracker>
        <HeaderBar
          title="Troubleshooting Writes"
          subtitle="Learn about the tools used to troubleshoot POST requests"
        />
      </NavigationScrollTracker>
    </SmallWidthSection>

    <ShortSection>
      <NavigationScrollTrackerWithAnchor headingLevel="h3" title="Overview">
        <p>
          Merge’s API provides tools that allow you to easily troubleshoot any issues with POST
          requests to Merge.
        </p>
        <p>This consists of:</p>
        <ul>
          <li>
            <strong>Unified error and warning messaging</strong> directly within API responses
          </li>
          <li>
            <strong>Optional debug mode</strong> that lists logs of requests made from Merge to the
            relevant third-party API associated with the POST request to Merge
          </li>
        </ul>
        <aside className="mt-3">
          View the <strong>full list of possible errors and warnings</strong> in{" "}
          <Link to={LINKS.MERGE_WRITES_WARNINGS_ERRORS.linkTo}>
            Writes Errors and Warnings Reference
          </Link>
          .
        </aside>
      </NavigationScrollTrackerWithAnchor>
    </ShortSection>
    <SectionDivider />

    <Section>
      <NavigationScrollTrackerWithAnchor headingLevel="h3" title="Warnings and errors">
        <p>
          The <code>warnings</code> field contains details on issues that don’t stop the request
          from being successful.
        </p>
        <p>
          The <code>errors</code> field contains details on issues that cause the request to fail.
        </p>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor headingLevel="h4" title="Warnings">
        <p>
          Warnings in POST responses notify you about issues with the request that{" "}
          <strong>did not prevent the Common Model instance from being created</strong>.
        </p>
        <p>
          For example, if the request contains an <strong>unrecognized field</strong>, we will{" "}
          <strong>ignore the field</strong> and notify you with a{" "}
          <strong>warning in the response</strong>.
        </p>
        <CodeExampleExplainer className="mt-6">Example</CodeExampleExplainer>
        <CodeExample
          folder={CODE_FOLDER}
          fileBaseName="warning"
          colorScheme="light"
          hideCopyButtonWithHeader
          hasLineNumbers={false}
          codeBlockName={
            <p>
              Example <code>warning</code> in POST response
            </p>
          }
        />
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor headingLevel="h4" title="Errors">
        <p>
          Errors in POST responses notify you about issues with the request that{" "}
          <strong>prevented the Common Model instance from being created</strong>.
        </p>
        <p>
          For example, if the request was <strong>missing a required field</strong>, then we will{" "}
          <strong>not create the Common Model instance</strong> and{" "}
          <strong>return an error response</strong> instead.
        </p>
        <CodeExampleExplainer className="mt-6">Example</CodeExampleExplainer>
        <CodeExample
          folder={CODE_FOLDER}
          fileBaseName="error"
          colorScheme="light"
          hideCopyButtonWithHeader
          hasLineNumbers={false}
          codeBlockName={
            <p>
              Example <code>error</code> in POST response
            </p>
          }
        />
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor headingLevel="h4" title="Properties">
        <div className="d-flex flex-column align-items-center mb-16 mt-9">
          <table>
            <thead>
              <tr>
                <td>Property</td>
                <td>Description</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <code>problem_type</code>
                </td>
                <td>The type of error or warning from Merge’s API.</td>
              </tr>
              <tr>
                <td>
                  <code>title</code>
                </td>
                <td>A human-readable summary of the error or warning.</td>
              </tr>
              <tr>
                <td>
                  <code>detail</code>
                </td>
                <td>A description of what specifically caused the error or warning.</td>
              </tr>
              <tr>
                <td>
                  <code>source</code>
                </td>
                <td>
                  If applicable, this property denotes the ID of the source of the error / warning
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </NavigationScrollTrackerWithAnchor>
    </Section>

    <Section>
      <NavigationScrollTrackerWithAnchor headingLevel="h3" title="Debug Mode">
        <p>
          Logs of requests made from Merge to third-party platforms are available for transparency.
        </p>
        <p>
          <strong>Debug Mode</strong> makes it easy to retrieve the logs for requests made from
          Merge to a third-party’s API as a result of a POST request to Merge.
        </p>
        <p>
          This is most useful for cases where detail beyond the standard errors and warnings from
          Merge is helpful to troubleshoot, for example:
        </p>
        <ul>
          <li>
            Troubleshooting errors stemming from the third-party API (see error type{" "}
            <Link to={`${LINKS.MERGE_WRITES_WARNINGS_ERRORS.linkTo}#provider-error`}>
              <code>PROVIDER_ERROR</code>
            </Link>
            )
          </li>
          <li>
            Troubleshooting generic errors from Merge (see error type{" "}
            <Link to={`${LINKS.MERGE_WRITES_WARNINGS_ERRORS.linkTo}#error`}>
              <code>ERROR</code>
            </Link>
            )
          </li>
        </ul>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor headingLevel="h4" title="Usage">
        <p>
          To use Debug Mode, include <code>is_debug_mode=true</code> as a query parameter in the
          POST request.
        </p>
        {/* This is bash, but javascript lets us use the inline comments */}
        <CodeBlock
          language="javascript"
          colorScheme="highContrastDark"
          text="GET https://api.merge.dev/api/ats/v1/candidates/* highlight-inline-start */?is_debug_mode=true/* highlight-inline-end */"
          hasLineNumbers={false}
          isVerticallyPadded={false}
          hideCopyButtonWithHeader
        />
        <p className="mt-9">
          In Debug Mode, the response includes the <code>logs</code> field, which is an array of log
          objects containing detail on outbound requests made to the end user’s API provider during
          the request.
        </p>
        <CodeExample
          folder={CODE_FOLDER}
          fileBaseName="logs"
          colorScheme="light"
          hideCopyButtonWithHeader
          hasLineNumbers={false}
          codeBlockName={
            <p>
              Example <code>logs</code> in POST response
            </p>
          }
        />
        <p>Each log in the response will contain the following properties:</p>
        <div className="d-flex flex-column align-items-center mt-9">
          <table style={{ height: "100%" }}>
            <thead>
              <tr>
                <td>Property</td>
                <td>Description</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <code>log_id</code>
                </td>
                <td>
                  <p>
                    The ID of the log in Merge, which can be used to get more detail on the log by:
                  </p>
                  <ol>
                    <li>
                      Sending a GET request to <code>{"/api/logs/{log_id}"}</code>
                    </li>
                    <li>
                      Viewing it in the Merge App by going to{" "}
                      <code>{"https://app.merge.dev/logs/{log_id}"}</code>
                    </li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td>
                  <code>dashboard_view</code>
                </td>
                <td>A direct link to the log in Merge Dashboard.</td>
              </tr>
              <tr style={{ borderBottom: "none" }}>
                <td>
                  <code>log_summary</code>
                </td>
                <td>Summary information about the log.</td>
              </tr>
            </tbody>
          </table>
          <ExpandableTable
            style={{ marginLeft: "16px" }}
            title={
              <>
                <code>log_summary</code> properties
              </>
            }
            colSpan={2}
          >
            <tr>
              <td>
                <code>url</code>
              </td>
              <td>The third-party API endpoint that the request from Merge was made to.</td>
            </tr>
            <tr>
              <td>
                <code>method</code>
              </td>
              <td>The type of request that Merge made to the third-party API endpoint.</td>
            </tr>
            <tr>
              <td>
                <code>status_code</code>
              </td>
              <td>
                The status code received by Merge in the response from the third-party API endpoint.
              </td>
            </tr>
          </ExpandableTable>
        </div>
      </NavigationScrollTrackerWithAnchor>
    </Section>

    <Section>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </Section>
  </StaticFancyPageContainerWithTOC>
);

export default TroubleshootingWrites;
