import React from "react";
import styled from "styled-components";
import RelatedUseCase from "templates/docs/marketing/components/RelatedUseCase";
import { UseCaseWorkflowType } from "../../types/UseCaseWorkflowTypes";
import DocumentationSection from "components/docs/DocumentationSection";
const FeaturedUseCasesSection = styled.div`
  background-color: #f5f8ff;
`;

const RowGap = styled.div`
  flex-wrap: wrap;
`;

type FeaturedUseCasesProps = {
  featuredUseCases: UseCaseWorkflowType[];
};
const FeaturedUseCases = ({ featuredUseCases }: FeaturedUseCasesProps) => {
  return (
    <FeaturedUseCasesSection className="mt-12 p-6 pr-3 pt-5 rounded-xl">
      <DocumentationSection title="Featured use cases" />
      <div>Look through our most popular use cases across our categories.</div>
      <RowGap className="d-flex mt-2">
        {featuredUseCases &&
          featuredUseCases.length > 0 &&
          featuredUseCases.map((useCase) => {
            const { use_case, workflow_slug } = useCase;
            return (
              <RelatedUseCase
                isFeatured={true}
                useCase={use_case}
                workflowSlug={workflow_slug}
                background="#FFFFFF"
              />
            );
          })}
      </RowGap>
    </FeaturedUseCasesSection>
  );
};

export default FeaturedUseCases;
