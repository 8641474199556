import React from "react";

import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { Col, Row } from "react-bootstrap";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { Link, PageProps } from "gatsby";
import DocumentationSection from "components/docs/DocumentationSection";
import LINKS, { MERGE_PRICING_PAGE } from "components/docs/navigation/links";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import { PricingInfoCard } from "components/docs/shared-components/NoticeCards";
import { AsideTitle } from "../field-mappings/create-field-mapping/for-linked-account";
import { WrappedText } from "../field-mappings/target-fields";
import { Badge } from "@merge-api/merge-javascript-shared";

/**
 * Creates an Overview for Remote Fields guide
 */
const RemoteFieldsOverview = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const title = "Remote Field Classes";
  const description =
    "Read and write non-mapped data in a standardized format via Merge’s Unified API.";

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} isBeta={true} className="pb-9">
        <PricingInfoCard className="mb-9 mt-0">
          This feature is only available to customers on our{" "}
          <strong className="weight-semibold">Professional</strong> and{" "}
          <strong className="weight-semibold">Enterprise</strong> plans. View the{" "}
          <a href={MERGE_PRICING_PAGE} target="_blank" rel="noreferrer">
            {" "}
            Merge Plans
          </a>{" "}
          to learn more.
        </PricingInfoCard>
      </HeaderBar>
      <DocumentationSection title="Overview">
        <>
          <p>
            Merge’s Remote Field Classes feature allows you to programmatically access additional
            fields that are not mapped to Merge’s Common Model fields. These non-mapped fields can
            be both standard fields on the third-party platform and custom fields on a Linked
            Account.
          </p>
          <p>
            Remote Field Classes also surfaces metadata about each field, such as display name and
            type.
          </p>
          <p>
            Merge will generally leave field values untouched, but will normalize values into a
            standardized format if necessary, such as DateTime fields.
          </p>
          <aside className="py-5 mt-6">
            <AsideTitle className="mb-3">
              <i className="fe fe-help-circle mr-1.5" />
              How is this different from Remote Data and Field Mapping?
            </AsideTitle>
            <div className="d-flex">
              <WrappedText className="d-flex">
                <p>
                  <Link to={LINKS.REMOTE_DATA.linkTo}>Remote Data</Link> is all the raw data from a
                  model in the original format Merge received it in.
                </p>
              </WrappedText>
            </div>
            <p className="m-0">
              <div className="d-flex flex-column">
                <p>
                  <Link to={LINKS.MERGE_FIELD_MAPPING_OVERVIEW.linkTo}>Field Mapping</Link> and
                  Remote Field Classes both build on top of Remote Data to return fields in a
                  normalized format:
                </p>
                <ul className="mb-0">
                  <li className="mb-2">
                    <p>
                      <strong className="weight-semibold">Field Mapping</strong> is best for reading
                      fields from the third-party by mapping them to a Merge Common Model
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      <strong className="weight-semibold">Remote Field Classes</strong> is best for
                      accessing highly variable custom fields and writing those fields back to your
                      users’ third-party platform
                    </p>
                  </li>
                </ul>
              </div>{" "}
            </p>
          </aside>
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Supported categories" className="mb-9">
        <p>Remote Field Classes are currently available for:</p>
        <ul>
          <li>
            <div className="d-flex align-items-center">
              Accounting{" "}
              <Badge className="ml-3" size="md" color="blue">
                Beta
              </Badge>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center">
              CRM{" "}
              <Badge className="ml-3" size="md" color="blue">
                Beta
              </Badge>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center">
              Ticketing{" "}
              <Badge className="ml-3" size="md" color="blue">
                Beta
              </Badge>
            </div>
          </li>
        </ul>
        <p>
          Please reach out via our{" "}
          <Link to={"https://airtable.com/app0BstmXhRJdM6gS/shr6MCbdh6pP5Wtad"} target="_blank">
            Product Feedback form
          </Link>{" "}
          if you are interested in other categories!
        </p>
      </DocumentationSection>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </StaticPageContainer>
  );
};

export default RemoteFieldsOverview;
