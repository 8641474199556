import React, { useState } from "react";
import styled from "styled-components";
import {
  getTagForModelName,
  getDefinitionForModelName,
  getModelDefinitionForSchemaRepresentedByTag,
  APICategory,
  Badge,
  Button,
  ButtonVariant,
} from "@merge-api/merge-javascript-shared";
import IntegrationsInfoHeaders, { SelectedIntegrationInfo } from "./IntegrationInfoHeaders";
import CommonModelFieldIntegrationSupportRow from "./CommonModelFieldIntegrationSupportRow";
import SuggestedSearch from "./SuggestedSearch";
import { AvailableIntegration, hydrateAvailableActions } from "../../../hooks/useAvailableActions";
import { HTTPMethod } from "types/types";
import { OpenAPIV3 } from "openapi-types";
import { Integration } from "../../../templates/docs/integrations/IntegrationsSandboxPage";
import { getDefaultCommonModelforCategory } from "../../../helpers/helpers";
import { CSVLink } from "react-csv";
import { checkIfFieldIsSupported } from "./CommonModelFieldIntegrationSupportRow";
import MergeLogo from "../../../assets/svg/logos/merge/merge-features-table.svg";
import { ActionMeta } from "react-select";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import { ADDITIONAL_COMMON_MODEL_INFO, DeprecationStage } from "../assets/constants";

export const SELECT_ALL = "Select all";

type CommonModelLabelProps = {
  numberOfFields: number;
};

type TableCellProps = {
  isLastCell?: boolean;
  isLastModel?: boolean;
};

export type SelectedIntegration = {
  integration: SelectedIntegrationInfo;
};

type CategoryIntegrationObject = {
  integration: Integration;
};

interface DropdownOption {
  value: string;
  label: string;
}

type SupportedFieldsTableProps = {
  availableActions: Record<string, Partial<Record<HTTPMethod, AvailableIntegration[]>>> | undefined;
  categoryName: APICategory;
  integrations: CategoryIntegrationObject;
  categorySchema: OpenAPIV3.Document;
};

type CommonModelProperties = {
  [property: string]: string;
};

const CommonModelLabel = styled.div<CommonModelLabelProps>`
  height: ${(props) => `${props.numberOfFields * 36}px`};
  writing-mode: vertical-rl;
  box-shadow: 0px -1px 0px #f1f3f4;
`;

const TableCell = styled.div<TableCellProps>`
  box-shadow: ${(props) => {
    if (props.isLastCell && !props.isLastModel) {
      return "1px 0px 0px #eaeef3, 0px 1px 0px #C8CFDA";
    }
    if (props.isLastCell && props.isLastModel) {
      return "1px 0px 0px #eaeef3, 0px 1px 0px #eaeef3";
    }
    return "1px 0px 0px #eaeef3, 0px 1px 0px #eaeef3";
  }};
  border-bottom-right-radius: ${(props) => (props.isLastCell && props.isLastModel ? "8px" : "0")};
`;

const getCommonModelSupportedIntegrationsAndSupportedFields = (
  hydratedActions: any, // this type doesn't work: HydratedOperationIntegration[],
) => {
  const hydratedActionsGet = hydratedActions["GET"];
  const integrationSupportedFields = hydratedActionsGet
    ? hydratedActionsGet.reduce((integrationSupportedFields: any, supportedIntegration: any) => {
        const { integration, supportedResponseFields } = supportedIntegration;
        return {
          ...integrationSupportedFields,
          [integration.name]: supportedResponseFields,
        };
      }, {})
    : {};

  return integrationSupportedFields;
};

const filterMergeGeneratedProperties = (supportedProperties: string[]) => {
  const mergeGeneratedProperties = ["id", "field_mappings", "remote_was_deleted", "custom_fields"];
  return supportedProperties.filter((supportedProperty) => {
    return !mergeGeneratedProperties.includes(supportedProperty);
  });
};

const getInitialCommonModels = (
  categoryName: APICategory,
  commonModelQueryParam: string | string[] | null,
) => {
  if (commonModelQueryParam && commonModelQueryParam.length > 0) {
    return commonModelQueryParam.toString().split(",");
  } else {
    return getDefaultCommonModelforCategory(categoryName);
  }
};

const getAllIntegrations = (integrations: CategoryIntegrationObject) => {
  return Object.values(integrations).map((integration) => {
    const { name } = integration.integration;
    return name;
  });
};

const getInitialIntegrations = (
  integrations: CategoryIntegrationObject,
  integrationQueryParam: string | string[] | null,
) => {
  const integrationsList = getAllIntegrations(integrations);

  if (integrationQueryParam && integrationQueryParam.length > 0) {
    return integrationQueryParam.toString().split(",");
  } else {
    return integrationsList;
  }
};

/** Gets deprecation info for a given common model */
const getCommonModelDeprecationInfo = (category: string, commonModel: string) => {
  return ADDITIONAL_COMMON_MODEL_INFO[category][commonModel] || {};
};

const SupportedFieldsTable = ({
  availableActions,
  categoryName,
  integrations,
  categorySchema,
}: SupportedFieldsTableProps) => {
  const location = useLocation();
  const { commonModel: commonModelQueryParam, integration: integrationQueryParam } =
    queryString.parse(location.search);

  const [selectedCommonModels, setSelectedCommonModels] = useState(
    getInitialCommonModels(categoryName, commonModelQueryParam),
  );
  const [selectedCommonModelsDropdown, setSelectedCommonModelsDropdown] = useState(
    getInitialCommonModels(categoryName, commonModelQueryParam),
  );

  const [selectedIntegrationsDropdown, setSelectedIntegrationsDropdown] = useState(
    getInitialIntegrations(integrations, integrationQueryParam),
  );

  const [selectedIntegrations, setSelectedIntegrations] = useState(
    getInitialIntegrations(integrations, integrationQueryParam),
  );

  const isAllIntegrationsSelected =
    getAllIntegrations(integrations).length === selectedIntegrations.length;

  const commonModelNames = Object.keys(availableActions!).filter((availableActionKey) => {
    const { deprecationStage } = getCommonModelDeprecationInfo(categoryName, availableActionKey);

    return (
      deprecationStage !== DeprecationStage.SUNSET && // filter out SUNSET stage common models
      availableActions![availableActionKey] &&
      availableActions![availableActionKey]["GET"]
    );
  });

  const categoryCommonModelProperties: CommonModelProperties = commonModelNames.reduce(
    (categoryCommonModelProperties, commonModelName) => {
      const commonModelTagName = getTagForModelName(commonModelName, categoryName as APICategory);
      const commonModelProperties = getModelDefinitionForSchemaRepresentedByTag(
        commonModelTagName,
        categoryName,
        categorySchema,
      )?.properties;
      if (commonModelProperties) {
        return { ...categoryCommonModelProperties, [commonModelName]: commonModelProperties };
      } else {
        return categoryCommonModelProperties;
      }
    },
    {},
  );

  const categoryIntegrationInformation = Object.values(integrations).reduce(
    (
      selectedCategoryIntegrationInformation: SelectedIntegrationInfo[],
      integrationInfo: Integration,
    ) => {
      const { name, slug, square_image } = integrationInfo.integration;
      if (selectedIntegrations.includes(name) || isAllIntegrationsSelected) {
        return [
          ...selectedCategoryIntegrationInformation,
          {
            integrationName: name,
            integrationSlug: slug,
            integrationSquareImage: square_image,
          },
        ];
      }
      return selectedCategoryIntegrationInformation;
    },
    [],
  );

  const handleCommonModelSelect = (
    selectedOptions: DropdownOption[],
    actionMeta: ActionMeta<DropdownOption>,
  ) => {
    const commonModels = Object.keys(categoryCommonModelProperties);

    const isSelectAllOptionSelected = selectedOptions.some((option) => option.value === SELECT_ALL);
    const selectAllMetaActionDeselected =
      actionMeta.action === "deselect-option" && actionMeta.option?.value === SELECT_ALL;

    const selectAllMetaActionSelected =
      actionMeta.action === "select-option" && actionMeta.option?.value === SELECT_ALL;

    const modelMetaActionSelected =
      actionMeta.action === "select-option" && actionMeta.option?.value !== SELECT_ALL;
    const modelMetaActionDeselected =
      actionMeta.action === "deselect-option" && actionMeta.option?.value !== SELECT_ALL;

    const selectedValues = selectedOptions.map((option) => option.value);

    if (selectAllMetaActionDeselected) {
      setSelectedCommonModels([]);
      setSelectedCommonModelsDropdown([]);
    }

    if (selectAllMetaActionSelected) {
      setSelectedCommonModels(commonModels);
      setSelectedCommonModelsDropdown([SELECT_ALL, ...commonModels]);
    }

    if (modelMetaActionSelected && !isSelectAllOptionSelected) {
      setSelectedCommonModels(selectedValues);
      setSelectedCommonModelsDropdown(selectedValues);
    }

    if (modelMetaActionDeselected && isSelectAllOptionSelected) {
      const deselectedOption = commonModels.find((option) => !selectedValues.includes(option));
      setSelectedCommonModels(
        selectedValues.filter((option) => option !== deselectedOption && option !== SELECT_ALL),
      );
      setSelectedCommonModelsDropdown(
        selectedValues.filter((option) => option !== deselectedOption && option !== SELECT_ALL),
      );
    } else if (modelMetaActionDeselected) {
      setSelectedCommonModels(selectedValues);
      setSelectedCommonModelsDropdown(selectedValues);
    }
  };

  const handleIntegrationSelect = (
    selectedOptions: DropdownOption[],
    actionMeta: ActionMeta<DropdownOption>,
  ) => {
    const integrationsList = getAllIntegrations(integrations);

    const isSelectAllOptionSelected = selectedOptions.some((option) => option.value === SELECT_ALL);
    const selectAllMetaActionDeselected =
      actionMeta.action === "deselect-option" && actionMeta.option?.value === SELECT_ALL;

    const selectAllMetaActionSelected =
      actionMeta.action === "select-option" && actionMeta.option?.value === SELECT_ALL;

    const modelMetaActionSelected =
      actionMeta.action === "select-option" && actionMeta.option?.value !== SELECT_ALL;
    const modelMetaActionDeselected =
      actionMeta.action === "deselect-option" && actionMeta.option?.value !== SELECT_ALL;

    const selectedValues = selectedOptions.map((option) => option.value);

    if (selectAllMetaActionDeselected) {
      setSelectedIntegrations([]);
      setSelectedIntegrationsDropdown([]);
    }

    if (selectAllMetaActionSelected) {
      setSelectedIntegrations(integrationsList);
      setSelectedIntegrationsDropdown([SELECT_ALL, ...integrationsList]);
    }

    if (modelMetaActionSelected && !isSelectAllOptionSelected) {
      setSelectedIntegrations(selectedValues);
      setSelectedIntegrationsDropdown(selectedValues);
    }

    if (modelMetaActionDeselected && isSelectAllOptionSelected) {
      const deselectedOption = integrationsList.find((option) => !selectedValues.includes(option));
      setSelectedIntegrations(
        selectedValues.filter((option) => option !== deselectedOption && option !== SELECT_ALL),
      );
      setSelectedIntegrationsDropdown(
        selectedValues.filter((option) => option !== deselectedOption && option !== SELECT_ALL),
      );
    } else if (modelMetaActionDeselected) {
      setSelectedIntegrations(selectedValues);
      setSelectedIntegrationsDropdown(selectedValues);
    }
  };

  const getCSVData = () => {
    const csvData: any[] = [];
    selectedIntegrations.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    const csvHeaders = ["Merge Properties", ...selectedIntegrations];

    csvData.push(csvHeaders);
    selectedCommonModels.forEach((commonModel) => {
      const filteredProperties = filterMergeGeneratedProperties(
        Object.keys(categoryCommonModelProperties[commonModel]),
      );
      const modelDefinition = getDefinitionForModelName(commonModel, categorySchema);

      const hydratedActions = modelDefinition
        ? hydrateAvailableActions(
            categorySchema,
            availableActions![commonModel],
            modelDefinition,
            categoryName,
            {},
            null,
          )
        : {};

      filteredProperties.forEach((property) => {
        const csvPropertyIntegrationsRow = [`${commonModel}.${property}`];
        const fieldSupportedIntegrations =
          getCommonModelSupportedIntegrationsAndSupportedFields(hydratedActions);
        const supportedIntegrationsForField = Object.keys(fieldSupportedIntegrations);

        const integrationsInfoForField = categoryIntegrationInformation.reduce(
          (integrationsInfoForField: any, integration) => {
            if (
              supportedIntegrationsForField.includes(integration.integrationName) &&
              checkIfFieldIsSupported(
                fieldSupportedIntegrations,
                integration.integrationName,
                property,
              )
            ) {
              integrationsInfoForField.push("Yes");
            } else {
              integrationsInfoForField.push("No");
            }
            return integrationsInfoForField;
          },
          [],
        );
        csvData.push(csvPropertyIntegrationsRow.concat(integrationsInfoForField));
      });
    });

    return csvData;
  };

  return (
    <>
      <hr className="border-t-[0.5px] border-gray-10 m-0 p-0" />
      <div className="w-full mt-9">
        <div className="grid grid-cols-1 gap-y-9 md:grid-cols-2 md:gap-x-10 md:gap-y-0 mb-6">
          <SuggestedSearch
            title="Select Common Models"
            dropDownOptions={[SELECT_ALL, ...Object.keys(categoryCommonModelProperties)]}
            handleOptionSelect={handleCommonModelSelect}
            selectedOptions={selectedCommonModelsDropdown}
          />
          <SuggestedSearch
            title={"Select integrations"}
            dropDownOptions={[SELECT_ALL, ...getAllIntegrations(integrations)]}
            handleOptionSelect={handleIntegrationSelect}
            selectedOptions={selectedIntegrationsDropdown}
          />
        </div>

        <div className="flex justify-end">
          <Button variant={ButtonVariant.TertiaryWhite} size="sm">
            <CSVLink className="text-black" data={getCSVData()}>
              Export as CSV
            </CSVLink>
          </Button>
        </div>
        <div className="flex my-6">
          <div>
            <TableCell className="bg-white h-9 font-semibold text-gray-90 text-base py-2 px-3 ml-9 mb-0 shadow-sm rounded-tl-lg rounded-tr-lg flex flex-row items-center">
              <MergeLogo className="mr-2" /> Merge
            </TableCell>
            {selectedCommonModels.map((selectedCommonModel, cmIndex) => {
              const { deprecationStage, fields } = getCommonModelDeprecationInfo(
                categoryName,
                selectedCommonModel,
              );

              const selectedCommonModelProperties = filterMergeGeneratedProperties(
                Object.keys(categoryCommonModelProperties[selectedCommonModel]),
              ).filter(
                (field) =>
                  !fields || !(fields[field]?.deprecationStage === DeprecationStage.SUNSET),
              );
              const isLastModel = cmIndex === selectedCommonModels.length - 1;

              return (
                <div className="flex bg-white rounded-tl-lg rounded-bl-lg shadow-[0_6px_30px_-2px_rgba(0,0,0,0.12)]">
                  <CommonModelLabel
                    className="justify-end truncate py-3 pr-2 rotate-180 w-10 text-right font-semibold border-l border-gray-10 rounded-tr-lg rounded-br-lg"
                    numberOfFields={selectedCommonModelProperties.length}
                  >
                    {selectedCommonModel}
                    {deprecationStage === DeprecationStage.DEPRECATED && (
                      <Badge className="py-[0.3125rem] px-0.5 mt-1" color="yellow">
                        Deprecated
                      </Badge>
                    )}
                  </CommonModelLabel>
                  <div className="flex flex-col w-full">
                    {selectedCommonModelProperties.map((fieldName: string, index) => {
                      const isLastField = index === selectedCommonModelProperties.length - 1;

                      const isDeprecated =
                        fields &&
                        fields[fieldName]?.deprecationStage === DeprecationStage.DEPRECATED;

                      return (
                        <TableCell
                          className="flex flex-row w-full items-center h-9 py-2 px-3 text-left font-mono text-[13px] leading-24 font-normal text-slate-90"
                          isLastCell={isLastField}
                          isLastModel={isLastModel}
                        >
                          {fieldName}{" "}
                          {isDeprecated && (
                            <Badge className="ml-1" color="yellow">
                              Deprecated
                            </Badge>
                          )}
                        </TableCell>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="overflow-x-auto overflow-y-hidden w-full flex flex-nowrap rounded-b-lg shadow-[0_4px_20px_-4px_rgba(0,0,0,0.08)]">
            <div className="flex flex-col">
              <div className="flex">
                <IntegrationsInfoHeaders integrationInformation={categoryIntegrationInformation} />
              </div>
              {selectedCommonModels.map((selectedCommonModel) => {
                const { fields } = getCommonModelDeprecationInfo(categoryName, selectedCommonModel);

                const selectedCommonModelProperties =
                  categoryCommonModelProperties[selectedCommonModel];

                const modelDefinition = getDefinitionForModelName(
                  selectedCommonModel,
                  categorySchema,
                );

                const hydratedActions = modelDefinition
                  ? hydrateAvailableActions(
                      categorySchema,
                      availableActions![selectedCommonModel],
                      modelDefinition,
                      categoryName,
                      {},
                      null,
                    )
                  : {};

                return filterMergeGeneratedProperties(Object.keys(selectedCommonModelProperties))
                  .filter(
                    (field) =>
                      !fields || !(fields[field]?.deprecationStage === DeprecationStage.SUNSET),
                  )
                  .map(
                    (
                      fieldName: string,
                      index: number,
                      selectedCommonModelPropertiesArray: string[],
                    ) => {
                      const isLastRow = index == selectedCommonModelPropertiesArray.length - 1;
                      return (
                        <div className="flex">
                          <CommonModelFieldIntegrationSupportRow
                            fieldName={fieldName}
                            integrationInformation={categoryIntegrationInformation}
                            fieldSupportedIntegrations={getCommonModelSupportedIntegrationsAndSupportedFields(
                              hydratedActions,
                            )}
                            isLastRow={isLastRow}
                          />
                        </div>
                      );
                    },
                  );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SupportedFieldsTable;
