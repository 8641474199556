import React from "react";

import styled from "styled-components";
import { Col, Row, Table } from "react-bootstrap";
import {
  LeftCol,
  RightCol,
  RowWithoutNegativeMargin,
} from "pages/supplemental-data/remote-fields/pulling-data";
import CodeExample from "components/docs/code-examples/CodeExample";
import DocumentationSection from "../../components/docs/DocumentationSection";

import CrossGuideLinks from "../../components/docs/navigation/CrossGuideLinks";
import HeaderBar from "../../components/docs/layout/HeaderBar";
import DocsHelmet from "../../components/docs/shared-components/DocsHelmet";
import { StaticPageContainer } from "../../components/docs/shared-components/PageContainers";
import { RouterLocation } from "../../types/types";

interface Props {
  location: RouterLocation;
}

// Create space between list item markers and content - was too tight
const FormattedListItem = styled.li`
  padding-left: 8px;
`;

function DocumentationDataSyncPage({ location }: Props) {
  const title = "Syncing Data";
  const description = "Best practices for syncing data between you and Merge";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview">
        <p>When syncing data with Merge, we recommend a combination of webhooks and polling.</p>

        <ol className="pl-5 marker:text-lg">
          <Row>
            <Col>
              <RowWithoutNegativeMargin className="my-9">
                <LeftCol xs={12} md={6}>
                  <FormattedListItem className="mb-4 font-semibold">
                    <div className="font-semibold text-lg">Save your users' account token</div>
                  </FormattedListItem>
                  <div className="pl-2">
                    <p>
                      Get the <strong className="font-semibold">account token</strong> from the
                      linking process for an embedded Merge Link. Learn more in our{" "}
                      <a href="/get-started/link/#step-3">embedded Merge Link guide</a>.{" "}
                    </p>

                    <p>
                      You can also use the{" "}
                      <strong className="font-semibold">Linked Account linked webhook</strong> to
                      get the <strong className="font-semibold">account token</strong>. See the
                      example payload on the right and learn how to configure Merge Webhooks in our{" "}
                      <a href="/basics/webhooks/merge-webhooks">guide</a>.
                    </p>
                    <p>
                      To authenticate your API requests to Merge, save your users' account token in
                      your database. You will need the account token to poll for data in{" "}
                      <strong className="font-semibold">step 4</strong>.
                    </p>
                  </div>
                </LeftCol>

                <RightCol xs={12} md={6}>
                  <CodeExample
                    folder="basics/syncing-data"
                    codeBlockName="Example Linked Account linked webhook payload"
                    fileBaseName="linked-webhook-payload"
                    colorScheme="light"
                    hasLineNumbers={false}
                    isVerticallyPadded={false}
                    hideCopyButtonWithHeader
                  />
                </RightCol>
              </RowWithoutNegativeMargin>
            </Col>
          </Row>
          <Row>
            <Col>
              <RowWithoutNegativeMargin className="my-9">
                <LeftCol xs={12} md={6}>
                  <FormattedListItem className="mb-4 font-semibold">
                    <div className="font-semibold text-lg">
                      Sync data when Merge emits a sync notification webhook
                    </div>
                  </FormattedListItem>
                  <div className="pl-2">
                    <div className="mb-9">
                      <p>
                        We recommend using the{" "}
                        <strong className="font-semibold">Linked Account synced webhooks</strong> to
                        manage sync activities at scale. Whenever you receive a sync notification
                        webhook for a Linked Account, start pulling data and kick off the logic in{" "}
                        <strong className="font-semibold">step 3</strong>.
                      </p>
                    </div>
                    <div className="font-semibold">Important fields</div>
                    <div className="overflow-auto my-4">
                      <Table>
                        <tbody>
                          <tr>
                            <td className="pl-0">
                              <code>hook.event</code>
                            </td>
                            <td>
                              <p>The event type that triggered the webhook.</p>
                              <p>
                                See our{" "}
                                <a href="/basics/webhooks/merge-webhooks/">webhooks guide</a> for
                                more information.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="pl-0">
                              <code>linked_account.id</code>
                            </td>
                            <td>The ID of the associated Linked Account.</td>
                          </tr>
                          <tr>
                            <td className="pl-0">
                              <code>data.sync_status</code>
                            </td>
                            <td>
                              <p>
                                Handle edge cases when <code>last_sync_result</code> is{" "}
                                <code>FAILED</code> or <code>PARTIALLY SYNCED</code>.
                              </p>
                              <p>
                                See our Help Center article on{" "}
                                <a
                                  href="https://help.merge.dev/en/articles/8184193-merge-sync-statuses"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  sync statuses
                                </a>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </LeftCol>

                <RightCol xs={12} md={6}>
                  <CodeExample
                    folder="basics/syncing-data"
                    codeBlockName="Example Linked Account synced webhook payload"
                    fileBaseName="sync-webhook-payload"
                    colorScheme="light"
                    hasLineNumbers={false}
                    isVerticallyPadded={false}
                    hideCopyButtonWithHeader
                  />
                </RightCol>
              </RowWithoutNegativeMargin>
            </Col>
          </Row>
          <Row>
            <Col>
              <RowWithoutNegativeMargin className="my-9">
                <LeftCol xs={12} md={6}>
                  <FormattedListItem className="mb-4 font-semibold">
                    <div className="font-semibold text-lg">
                      Create functions for efficiently syncing data
                    </div>
                  </FormattedListItem>
                  <div className="pl-2">
                    <div className="mb-9">
                      <p>
                        Store the timestamp of{" "}
                        <strong className="font-semibold">
                          when you last started pulling data
                        </strong>{" "}
                        from Merge as <code>modified_after</code>. Use this timestamp in subsequent
                        API requests to pull updates from Merge since your last sync.
                      </p>

                      <p>
                        Use the expand parameter to pull multiple models that are related to each
                        other instead of making multiple pulls for related information.
                      </p>
                    </div>

                    <div className="font-semibold">Query parameters</div>
                    <hr className="my-4" />
                    <div>
                      <div className="d-flex w-3/5 mb-3">
                        <div className="mr-2 text-blue weight-semibold">GET</div>
                        <div>
                          {"/{endpoint}/"}
                          <strong className="weight-semibold">
                            {"?modified_after={timestamp}"}
                          </strong>
                        </div>
                      </div>
                      <p>Only pull data that has been changed or created since your last sync. </p>
                      <p>
                        For example, you can ask for <code>modified_after=2021-03-30T20:44:18</code>
                        , and only pull items that are new or changed.
                      </p>
                    </div>
                    <hr className="my-4" />
                    <div>
                      <div className="d-flex mb-3">
                        <div className="mr-2 text-blue weight-semibold">GET</div>
                        <div>
                          {"/{endpoint}/"}
                          <strong className="weight-semibold">
                            {"?expand={common_model_name}"}
                          </strong>
                        </div>
                      </div>
                      <p>Pull related model information with a single API request.</p>
                      <p>
                        For example, if you are querying for candidates and also want details about
                        associated applications, you can <code>expand=applications</code>, and Merge
                        will return the actual application objects instead of just the{" "}
                        <code>application_id</code>.
                      </p>
                    </div>
                  </div>
                </LeftCol>

                <RightCol xs={12} md={6}>
                  <CodeExample
                    folder="basics/syncing-data"
                    codeBlockName="Example code snippet"
                    fileBaseName="example-code-1"
                    colorScheme="dark"
                    hasLineNumbers
                    isVerticallyPadded={false}
                  />
                </RightCol>
              </RowWithoutNegativeMargin>
            </Col>
          </Row>
          <Row>
            <Col>
              <RowWithoutNegativeMargin className="my-9">
                <LeftCol xs={12} md={6}>
                  <FormattedListItem className="deprecated-mb-5 font-semibold">
                    <div className="font-semibold text-lg">
                      Sync periodically and poll using /sync-status endpoint
                    </div>
                  </FormattedListItem>
                  <div className="pl-2">
                    <aside className="deprecated-mt-4 deprecated-mb-4">
                      <p>
                        <b className="font-semibold">
                          Make sure to implement polling and don&apos;t rely entirely on
                          notification webhooks.
                        </b>
                      </p>

                      <p className="">
                        Webhooks can fail for a variety of reasons such as downtime or failed
                        processing.
                      </p>
                      <p className="mb-0">
                        Merge does attempt to redeliver multiple times using exponential backoff,
                        but we still recommend calling your sync functions periodically every 24
                        hours.
                      </p>
                    </aside>

                    <p>
                      Make a request to our <b className="font-semibold">/sync-status</b> endpoint,
                      which returns an array of syncing statuses for all models in a category. See{" "}
                      <a href="/hris/sync-status/">API reference</a> to learn more.
                    </p>
                    <ul className="list-disc pl-5">
                      <li>
                        <p>
                          If status is <code>PARTIALLY SYNCED</code> or <code>DONE</code> , go ahead
                          and retrieve data. If another sync has not started, since the last time
                          you pulled data, there will not be new data.
                        </p>
                      </li>
                      <li>
                        <p>
                          If status is <code>SYNCING</code> , continue pinging
                        </p>
                      </li>
                    </ul>
                  </div>
                </LeftCol>

                <RightCol xs={12} md={6}>
                  <CodeExample
                    folder="basics/syncing-data"
                    codeBlockName="Example code snippet"
                    fileBaseName="example-code-2"
                    colorScheme="dark"
                    hasLineNumbers
                    isVerticallyPadded={false}
                  />
                </RightCol>
              </RowWithoutNegativeMargin>
            </Col>
          </Row>
        </ol>
      </DocumentationSection>

      <CrossGuideLinks location={location} style={{ marginTop: "12px " }} />
    </StaticPageContainer>
  );
}

export default DocumentationDataSyncPage;
