import { hydratedParametersFromModelDefinition } from "@merge-api/merge-javascript-shared";
import type { OpenAPIV3 } from "openapi-types";
import React from "react";
import ParameterRow from "../ParameterRow";
import DeprecatedFieldAlert from "../shared-components/DeprecatedFieldAlert";
import useDeprecatedCommonModel from "../hooks/useDeprecatedCommonModel";
import { DeprecationStage } from "../assets/constants";

type OpenAPIDocument = OpenAPIV3.Document;
type OpenAPIBaseSchemaObject = OpenAPIV3.BaseSchemaObject;

interface NestedCommonModelProps {
  document: OpenAPIDocument;
  modelDefinition: OpenAPIBaseSchemaObject;
  modelName: string;
}

interface Params {
  document: OpenAPIDocument;
  displayNestedObjects?: boolean;
  modelName: string;
  modelDefinition: OpenAPIBaseSchemaObject;
  /**
   * The caller must pass in the NestedCommonModel component, to avoid circular dependencies,
   * because the NestedCommonModel component imports ParameterRowList.
   */
  addEnumDescription?: boolean;
  NestedCommonModelComponent?: React.FC<NestedCommonModelProps>;
}

/**
 * Return an array of ParameterRow components.
 */
export const getParameterRows = ({
  document,
  displayNestedObjects,
  modelName,
  modelDefinition,
  addEnumDescription = true,
  NestedCommonModelComponent,
}: Params) =>
  hydratedParametersFromModelDefinition(document, modelDefinition, addEnumDescription)
    // filter out any fields that are marked as SUNSET
    .filter(({ name }) => {
      const { fields } = useDeprecatedCommonModel({
        category: modelDefinition["x-merge-category"],
        commonModel: modelName,
      });

      return !fields || !fields[name] || fields[name].deprecationStage !== DeprecationStage.SUNSET;
    })
    .map(({ name, type, required, deprecated, description, nested }) => {
      const { fields } = useDeprecatedCommonModel({
        category: modelDefinition["x-merge-category"],
        commonModel: modelName,
      });

      // two sources of truth on deprecation for fields for now.
      // will eventually move all of this to backend, but for now... this :/
      const isDeprecated =
        deprecated ||
        (fields && fields[name] && fields[name].deprecationStage === DeprecationStage.DEPRECATED);

      return (
        <ParameterRow
          key={name}
          name={name}
          type={type}
          deprecated={isDeprecated}
          required={false}
          description={description}
        >
          <>
            {isDeprecated && (
              <DeprecatedFieldAlert modelName={modelName} fieldName={name} className="mt-3 mb-6" />
            )}
            {/* Sub-object to display */}
            {nested && displayNestedObjects && NestedCommonModelComponent ? (
              <div className="mt-3">
                <NestedCommonModelComponent
                  modelDefinition={nested.modelDefinition}
                  modelName={nested.name}
                  document={document}
                />
              </div>
            ) : undefined}
          </>
        </ParameterRow>
      );
    }) ?? [];
