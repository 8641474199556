import React from "react";
import HeaderBar from "components/docs/layout/HeaderBar";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import StaticFancyPageContainer from "components/docs/shared-components/StaticFancyPageContainer";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import NavigationScrollTrackerWithAnchor from "components/docs/navigation/NavigationScrollTrackerWithAnchor";
import styled from "styled-components";
import NavigationScrollTracker from "components/docs/navigation/NavigationScrollTracker";
import TwoColumnCenteredSection from "components/docs/sections/TwoColumnCenteredSection";
import { StaticImage } from "gatsby-plugin-image";
import { palette } from "styles/theme";
import GetStartedLeftSidebar from "components/docs/get-started/GetStartedLeftSidebar";
import { Link } from "gatsby";
import LINKS from "components/docs/navigation/links";
import GetStartedOnboardingStep from "components/docs/get-started/GetStartedOnboardingStep";
import GlowButton from "components/generic/Button/GlowButton";
import SectionDivider from "components/docs/shared-components/SectionDivider";

const StyledNavigationScrollTrackerWithAnchor = styled(NavigationScrollTrackerWithAnchor)`
  padding-left: 0px;

  @media (max-width: 991px) {
    padding-left: 24px;
  }
`;

const TwoColumnSection = styled(TwoColumnCenteredSection).attrs({ padding: 20 })`
  margin-top: 16px;
`;

const GetStartedPage = () => {
  return (
    <StaticFancyPageContainer leftSidebar={<GetStartedLeftSidebar />}>
      <DocsHelmet
        title="Merge — Get started"
        description="Learn how to use Merge's features via our guided introductions."
      />

      <SmallWidthSection fadeBackground={false}>
        <NavigationScrollTracker>
          <HeaderBar
            title="Welcome to Merge!"
            subtitle={"Let's introduce some key features and ideas."}
            styling="top-level"
          />
        </NavigationScrollTracker>
      </SmallWidthSection>

      <TwoColumnSection
        columnA={
          <>
            <StyledNavigationScrollTrackerWithAnchor title="Unified API" headingLevel="h3" />
            <p>One API that allows you to sync data from multiple third-party platforms.</p>
          </>
        }
        columnB={
          <StaticImage
            src="../../assets/images/docs/get-started/introduction/unified-api.png"
            alt="Unified API Image"
            quality={100}
            placeholder="none"
            layout="constrained"
            loading="eager"
          />
        }
      />

      <SectionDivider fadeBackground={false} />

      <TwoColumnSection
        columnA={
          <>
            <StyledNavigationScrollTrackerWithAnchor title="Common Model" headingLevel="h3" />
            <p>Data model that has been standardized across multiple third-party platforms.</p>
            <p>You&apos;ll receive and update your Common Model data through our Unified API.</p>
          </>
        }
        columnB={
          <StaticImage
            src="../../assets/images/docs/get-started/introduction/common-model.png"
            alt="Common Model Image"
            quality={100}
            placeholder="none"
            layout="constrained"
            loading="eager"
          />
        }
      />

      <SectionDivider fadeBackground={false} />

      <TwoColumnSection
        columnA={
          <>
            <StyledNavigationScrollTrackerWithAnchor title="Link" headingLevel="h3" />
            <p>
              UI component that guides your end user through connecting to third-party platforms.
            </p>
          </>
        }
        columnB={
          <StaticImage
            src="../../assets/images/docs/get-started/introduction/link.png"
            alt="Link Image"
            quality={100}
            placeholder="none"
            layout="constrained"
            loading="eager"
          />
        }
      />

      <SectionDivider fadeBackground={false} />

      <TwoColumnSection
        columnA={
          <>
            <StyledNavigationScrollTrackerWithAnchor title="Linked Account" headingLevel="h3" />
            <p>Account that has been connected to a third-party platform.</p>
            <p>These will be created by your end user via Link.</p>
          </>
        }
        columnB={
          <StaticImage
            src="../../assets/images/docs/get-started/introduction/linked-account.png"
            alt="Linked Account Image"
            quality={100}
            placeholder="none"
            layout="constrained"
            loading="eager"
          />
        }
      />

      <SectionDivider fadeBackground={false} />

      <TwoColumnSection
        columnA={
          <>
            <StyledNavigationScrollTrackerWithAnchor title="Dashboard" headingLevel="h3" />
            <p>Web application to configure and manage your integrations.</p>
            <p>
              Visit{" "}
              <a href="https://app.merge.dev" target="_blank" rel="noreferrer">
                Dashboard&thinsp;
                <i className="fe fe-arrow-up-right" />
              </a>
            </p>
          </>
        }
        columnB={
          <StaticImage
            src="../../assets/images/docs/get-started/introduction/dashboard.png"
            alt="Dashboard Image"
            quality={100}
            placeholder="none"
            layout="constrained"
            loading="eager"
          />
        }
      />

      <SectionDivider fadeBackground={false} />

      <SmallWidthSection fadeBackground={false}>
        <GetStartedOnboardingStep
          stepLabel="Next Up"
          stepTitle="In the next guide, try out the Unified API&hellip;"
          stepContent={
            <>
              <p className="mb-6">
                Start pulling test data with authenticated API requests in our Unified API guide.
              </p>
              <Link to={LINKS.GET_STARTED_UNIFIED_API.linkTo}>
                <GlowButton
                  color={palette.black}
                  showChevron
                  borderRadius={6}
                  text="Go to Unified API guide"
                />
              </Link>
            </>
          }
        />
      </SmallWidthSection>
    </StaticFancyPageContainer>
  );
};

export default GetStartedPage;
