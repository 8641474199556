import React from "react";

import CodeExample from "components/docs/code-examples/CodeExample";
import UnevenTwoColumnCenteredSection from "components/docs/sections/UnevenTwoColumnCenteredSection";
import CodeExampleExplainer from "components/docs/shared-components/CodeExampleExplainer";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import DocumentationSection from "components/docs/DocumentationSection";
import NestedCommonModel from "components/docs/NestedCommonModel";
import ParameterRow from "components/docs/ParameterRow";
import CodeBlock from "components/docs/shared-components/code-block/CodeBlock";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import FancyTable from "components/docs/shared-components/FancyTable";
import { RouterLocation } from "types/types";
import { APICategory } from "@merge-api/merge-javascript-shared";
import HeaderBar from "components/docs/layout/HeaderBar";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { PricingInfoCard } from "components/docs/shared-components/NoticeCards";
import LINKS, { MERGE_PRICING_PAGE } from "components/docs/navigation/links";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";

interface Props {
  location: RouterLocation;
}

const PassthroughMakingARequestPage = ({ location }: Props) => {
  const title = "Making a Passthrough Request";
  const description = "Make API requests directly to any of Merge’s supported integrations.";

  return (
    <StaticPageContainer>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description}>
        <PricingInfoCard className="mb-9 mt-0">
          This feature is only available to customers on our <b>Professional</b> or{" "}
          <b>Enterprise</b> plans. View the{" "}
          <a href={MERGE_PRICING_PAGE} target="_blank" rel="noreferrer">
            {" "}
            Merge Plans
          </a>{" "}
          to learn more.
        </PricingInfoCard>
      </HeaderBar>
      <DocumentationSection title="API endpoint">
        <>
          <p>
            Send POST requests to the URL below with the required body parameters to create a
            Passthrough Requests.
          </p>
          <CodeBlock
            text="https://api.merge.dev/api/{CATEGORY}/v1/passthrough"
            language="bash"
            hasLineNumbers={false}
            hideCopyButtonWithHeader
            hasInlineCopy
          />
          <p>
            Replace <code>CATEGORY</code> in the URL with{" "}
            {(Object.keys(APICategory) as APICategory[]).map((category, index, array) => (
              <>
                <code>{category}</code>
                {index !== array.length - 1 && ", "}
                {index === array.length - 2 && "or "}
              </>
            ))}{" "}
            depending on the relevant category you&apos;re making an API request to.
          </p>
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Body Parameters">
        <>
          <p>
            Reference the integration&apos;s API documentation to accurately fill out the body
            parameters for the specific Passthrough Request you&apos;re looking to make.
          </p>
          <ParameterRow
            name="method"
            type="String"
            required
            isShowingRequiredFlag
            description="The method for the third-party request, such as GET or POST."
          />
          <ParameterRow
            name="path"
            type="String"
            required
            isShowingRequiredFlag
            description="The path for the third-party request, such as /levels."
          />
          <ParameterRow
            name="data"
            type="String"
            required={false}
            isShowingRequiredFlag
            description="The data for the request body."
          />
          <ParameterRow
            name="headers"
            type="Object"
            required={false}
            isShowingRequiredFlag
            description='The headers to use for the request (Merge will handle the account&apos;s authorization headers). <b>`"Content-Type"` header is required for passthrough.</b> Choose content type corresponding to expected format of receiving server. Example for request_format JSON:<br/>'
          >
            <CodeBlock
              text='"Content-Type":"application/json"'
              language="html"
              hasLineNumbers={false}
              hideCopyButtonWithHeader
            />
          </ParameterRow>
          <ParameterRow
            name="multipart_form_data"
            type="MultipartFormField[]"
            required={false}
            isShowingRequiredFlag
            description="Pass an array of `MultipartFormField` objects in here instead of using the `data` param if `request_format` is set to `MULTIPART`."
          >
            <div className="mt-3">
              <NestedCommonModel
                document={{
                  openapi: "3.0.3",
                  info: { title: "dummy", version: "1.0" },
                  paths: {},
                  components: {},
                }}
                addEnumDescription={false}
                modelDefinition={
                  {
                    type: "object",
                    description:
                      "# The MultipartFormField Object\n### Description\nThe `MultipartFormField` object is used to represent fields in an HTTP request using `multipart/form-data`.\n\n### Usage Example\nCreate a `MultipartFormField` to define a multipart form entry.",
                    properties: {
                      name: {
                        type: "string",
                        minLength: 1,
                        description: "The name of the form field",
                        example: "resume",
                      },
                      data: {
                        type: "string",
                        minLength: 1,
                        description: "The data for the form field.",
                        example: "SW50ZWdyYXRlIGZhc3QKSW50ZWdyYXRlIG9uY2U=",
                      },
                      encoding: {
                        allOf: [
                          {
                            enum: ["RAW", "BASE64"],
                            type: "string",
                          },
                        ],
                        nullable: true,
                        default: "RAW",
                        description:
                          "The encoding of the value of `data`. Defaults to `RAW` if not defined.",
                        example: "BASE64",
                      },
                      file_name: {
                        type: "string",
                        nullable: true,
                        minLength: 1,
                        description: "The file name of the form field, if the field is for a file.",
                        example: "resume.pdf",
                      },
                      content_type: {
                        type: "string",
                        nullable: true,
                        minLength: 1,
                        description: "The MIME type of the file, if the field is for a file.",
                        example: "application/pdf",
                      },
                    },
                    required: ["data", "name"],
                  } as Parameters<typeof NestedCommonModel>[0]["modelDefinition"]
                }
                modelName="MultipartFormField"
              />
            </div>
          </ParameterRow>
          <ParameterRow
            name="request_format"
            type="Enum"
            required={false}
            isShowingRequiredFlag
            description="Use one of the following choices: `JSON`, `XML`, `MULTIPART`."
          />
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Accessing Linked Account Credentials">
        <>
          <p>
            Some third-party APIs require you to include credentials directly in the{" "}
            <code>path</code> or request body (<code>data</code>) when making Passthrough Requests
            to the third-party API on behalf of a linked account. You can securely access a linked
            account&apos;s stored credentials programmatically by including variables in double
            brackets (e.g. <code>&#123;&#123;USERNAME&#125;&#125;</code>).
          </p>
          <p>Below is the full list of variables available for use in Passthrough Requests:</p>
          <SmallWidthSection className="d-flex flex-column align-items-center mb-0 mt-0">
            <FancyTable>
              <thead>
                <tr>
                  <td>Variable</td>
                  <td>Description</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <code>API_URL_SUBDOMAIN</code>
                  </td>
                  <td>Third-party API URL subdomain.</td>
                </tr>
                <tr>
                  <td>
                    <code>API_KEY</code>
                  </td>
                  <td>Third-party API Key.</td>
                </tr>
                <tr>
                  <td>
                    <code>USERNAME</code>
                  </td>
                  <td>Basic auth username for third-party API.</td>
                </tr>
                <tr>
                  <td>
                    <code>PASSWORD</code>
                  </td>
                  <td>
                    <p>Basic auth password for third-party API.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <code>LINKED_ACCOUNT_ATTR.field_name</code>
                  </td>
                  <td>
                    <p>
                      If an account-specific credential is needed to make a request to a third-party
                      API, and is not otherwise covered in the other variables, you can access it
                      using this format.
                    </p>
                    <p>
                      <code>field_name</code> represents the name of the field in the third-party
                      API and should be replaced with that field name when using this in practice.
                    </p>
                  </td>
                </tr>
              </tbody>
            </FancyTable>
          </SmallWidthSection>
          <UnevenTwoColumnCenteredSection
            className="pt-0"
            columnA={
              <>
                <CodeExampleExplainer style={{ marginBottom: "12px" }}>
                  Example - Paylocity
                </CodeExampleExplainer>
                <p>
                  This is an example request body that demonstrates how the{" "}
                  <code>API_URL_SUBDOMAIN</code> variable can be used for Passthrough Requests to
                  Paylocity&apos;s API.
                </p>
              </>
            }
            columnB={
              <CodeExample
                folder="supplemental-data/passthrough/passthrough-variable-2"
                codeBlockName={<span>Example request body</span>}
                hasLineNumbers={false}
                isVerticallyPadded={false}
              />
            }
          />
          <p>
            Some third-party APIs require you to encode credentials using Base64 encoding. You can
            specify what needs to be encoded into Base64 format in your third-party request by
            wrapping that content between <code>&#123;BASE-64&#125;</code> tags in the passthrough
            request body.
          </p>
          <UnevenTwoColumnCenteredSection
            className="pt-6"
            columnA={
              <>
                <CodeExampleExplainer style={{ marginBottom: "12px" }}>
                  Example - Workday
                </CodeExampleExplainer>
                <p>
                  This is an example request body that demonstrates how the <code>BASE-64</code>{" "}
                  method can be used for Passthrough Requests to Workday&apos;s API.
                </p>
              </>
            }
            columnB={
              <CodeExample
                folder="supplemental-data/passthrough/base-64-example"
                codeBlockName={<span>Example request body</span>}
                hasLineNumbers={false}
                isVerticallyPadded={false}
              />
            }
          />
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Fetching with the Merge SDK">
        <>
          <p>
            See below for an example of how to create an authenticated Passthrough Request with
            Merge&apos;s SDK:
          </p>
          <CodeExample
            folder="supplemental-data/passthrough/sdk-request"
            codeBlockName="Passthrough Request via SDK"
          />
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Response">
        <>
          <p>The response to your query will look like the following:</p>
          <CodeExample
            folder="supplemental-data/passthrough/passthrough-payload"
            codeBlockName="Sample Response to Passthrough Request"
            colorScheme="light"
            hideCopyButtonWithHeader
            hasLineNumbers={false}
          />
        </>
      </DocumentationSection>
      <p>
        The Passthrough Request endpoint will return a <b>408 status</b> if there is a timeout in
        getting the response. If you have experienced delays or timeouts with normal Passthrough
        Requests we recommend switching to use Merge&#39;s{" "}
        <a href={LINKS.PASSTHROUGH_REQUEST_ASYNC_PASSTHROUGH.linkTo} rel="noreferrer">
          Async Passthrough
        </a>{" "}
        requests.
      </p>
      <CrossGuideLinks location={location} />
    </StaticPageContainer>
  );
};

export default PassthroughMakingARequestPage;
