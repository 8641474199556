import React from "react";
import { RefreshCw, ChevronsUp, Calendar } from "lucide-react";
import ManualIcon from "../../../../assets/icons/manual.svg";
import styled from "styled-components";

const getSyncFrequencyConfiguration = (recommendedSyncFrequency: string) => {
  switch (recommendedSyncFrequency) {
    case "SYNC_FREQUENCY_PLAN_HIGHEST":
      return {
        color: "bg-orange-50",
        text: "Highest",
        icon: <ChevronsUp size={12} />,
      };
    case "SYNC_FREQUENCY_PLAN_DAILY":
      return {
        color: "bg-amber-40",
        text: "Daily",
        icon: <Calendar size={12} className="justify-content-center" />,
      };
    default:
      return {
        color: "bg-gray-50",
        text: "Manual",
        icon: <ManualIcon />,
      };
  }
};

const FrequencyContainer = styled.div`
  padding: 4px 7px 4px 6px;
  height: fit-content !important;
`;

const NonDesignSystemTextCaption = styled.div`
  font-size: 10px;
  line-height: 16px;
`;

type SyncFrequencyProps = {
  recommendedSyncFrequency: string;
};
const SyncFrequency = ({ recommendedSyncFrequency }: SyncFrequencyProps) => {
  const syncFrequencyConfiguration = getSyncFrequencyConfiguration(recommendedSyncFrequency);

  return (
    <div className="px-4 py-3 bg-gray-0 d-flex align-items-center rounded-lg border border-gray-10">
      <div className="d-flex flex-column align-self-start mt-1.5">
        <RefreshCw size={14} />
      </div>
      <div className="d-flex flex-column ml-2.5 mt-0.5">
        <div className="text-sm font-semibold">Typical sync frequency</div>
      </div>
      <div className="ml-auto">
        <FrequencyContainer
          className={`d-flex align-items-center justify-content-center text-white rounded ${syncFrequencyConfiguration?.color}`}
        >
          {syncFrequencyConfiguration?.icon}
          <NonDesignSystemTextCaption className="ml-1 font-medium">
            {syncFrequencyConfiguration?.text}
          </NonDesignSystemTextCaption>
        </FrequencyContainer>
      </div>
    </div>
  );
};
export default SyncFrequency;
