import React from "react";
import styled from "styled-components";
import { palette, spectrum } from "styles/theme";
import { ActiveStyledNavigationTileWithIcon, TileWithImageProps } from "../tile/Tile";

type Props = {
  tiles: Array<Omit<TileWithImageProps, "showChevron">>;
  scrollHeightPercentage?: number;
};

const Content = styled.div<{ $scrollHeightPercentage?: number }>`
  left: 0;
  height: ${({ $scrollHeightPercentage }) =>
    $scrollHeightPercentage ? $scrollHeightPercentage + "%" : "100%"};
  padding-bottom: 80px;
`;

const Wrapper = styled.div`
  position: sticky;
  top: 128px;
  left: 16px;
  width: calc(min(272px, 15vw));
  height: fit-content;
  padding: 0px 8px 16px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  margin-bottom: 24px;
`;

const IconWrapper = styled.div`
  padding-left: 20px;
  color: ${palette.black};
`;

const NavigationTile = styled(ActiveStyledNavigationTileWithIcon)`
  && > div {
    border: 1px solid transparent;
    outline: 2.5px solid ${spectrum.blue40};

    // Safari only hack (doesn't look like the design, but that's okay)
    // See https://bugs.webkit.org/show_bug.cgi?id=20807
    @supports (-webkit-hyphens: none) {
      outline-style: auto;
    }
  }
`;

const StickyTiledTableOfContents = ({ tiles, scrollHeightPercentage }: Props) => (
  <Content
    className="d-none d-lg-flex position-absolute col"
    $scrollHeightPercentage={scrollHeightPercentage}
  >
    <Wrapper>
      <Title>Get started</Title>
      {tiles.map(({ img, ...tile }) => (
        <NavigationTile
          key={tile.to}
          {...tile}
          img={<IconWrapper>{img}</IconWrapper>}
          showChevron={false}
        />
      ))}
    </Wrapper>
  </Content>
);

export default StickyTiledTableOfContents;
