import React from "react";
import HeaderBar from "components/docs/layout/HeaderBar";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import StaticFancyPageContainer from "components/docs/shared-components/StaticFancyPageContainer";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import NavigationScrollTrackerWithAnchor from "components/docs/navigation/NavigationScrollTrackerWithAnchor";
import styled from "styled-components";
import NavigationScrollTracker from "components/docs/navigation/NavigationScrollTracker";
import { StaticImage } from "gatsby-plugin-image";
import { palette, spectrum } from "styles/theme";
import GetStartedLeftSidebar from "components/docs/get-started/GetStartedLeftSidebar";
import { Link } from "gatsby";
import LINKS from "components/docs/navigation/links";
import { MERGE_END_USER_ORIGIN_ID_GUIDE } from "components/docs/navigation/links";
import GetStartedOnboardingStep from "components/docs/get-started/GetStartedOnboardingStep";
import SectionDivider from "components/docs/shared-components/SectionDivider";
import GetStartedURLBox from "components/docs/get-started/GetStartedURLBox";
import CodeExample from "components/docs/code-examples/CodeExample";
import { BasicNoticeCard } from "components/docs/shared-components/NoticeCards";
import { APICategory } from "@merge-api/merge-javascript-shared";
import TwoColumnCenteredSection from "components/docs/sections/TwoColumnCenteredSection";
import { Col, Row } from "react-bootstrap";
import { ChevronRight } from "lucide-react";
import { Wand } from "lucide-react";
import useCategories from "components/docs/hooks/useCategories";
import { GettingStartedStickyRightSidebar } from "components/docs/shared-components/StickyRightSidebar";
import DeprecatedH3 from "components/deprecated/DeprecatedH3";
import DeprecatedH4 from "components/deprecated/DeprecatedH4";
import DeprecatedH6 from "components/deprecated/DeprecatedH6";
import AsideBarContent from "components/generic/AsideBarContent";
import gif from "../../assets/gifs/HRIS-merge-link.gif";
import { CategoryPrimaryModelInfo } from "types/types";

const StyledHeaderBar = styled(HeaderBar)`
  .gatsby-image-wrapper {
    margin-top: 34px;
    margin-bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 3px 10px rgba(61, 63, 64, 0.3);
    border-radius: 10px;
    overflow: hidden;
  }
`;

const StyledNavigationScrollTrackerWithAnchor = styled(NavigationScrollTrackerWithAnchor)`
  padding-left: 0px;

  @media (max-width: 991px) {
    padding-left: 24px;
  }
`;

const StepOL = styled.ol`
  counter-reset: item;
  list-style-type: none;

  li:before {
    font-weight: 600;
    content: "Step " counter(item, decimal) ". ";
    counter-increment: item;
  }
`;

const StaticImageContainer = styled.div`
  margin-bottom: 44px;
`;

const BackgroundBlockingSectionDivider = styled(SectionDivider)`
  pointer-events: auto;

  &&&& {
    @media (min-width: 768px) {
      background-color: white;
      width: auto;
      margin-left: -40px;
      margin-right: -40px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
`;

const TwoColumnSection = styled(TwoColumnCenteredSection).attrs({
  padding: 20,
  fadeBackground: true,
  sectionWidth: 12,
})`
  background-color: white;
  padding-top: 80px;

  @media (max-width: 991px) {
    padding-top: 64px;
  }
`;

const YellowDiv = styled.div`
  background: ${spectrum.yellow0};
  border-radius: 14px;
  padding: 16px 20px;

  && {
    code {
      background: ${spectrum.yellow10};
      color: ${spectrum.yellow90};
    }

    .end-user-origin-id-example-text {
      font-size: 12px;
      line-height: 22px;
      color: ${spectrum.yellow70};
    }
  }
`;

const StyledBasicNoticeCard = styled(BasicNoticeCard)`
  margin-top: 32px;
  margin-botttm: 32px;

  p {
    margin-left: 8px;
  }
`;

const Table = styled.table`
  &&& {
    code {
      font-size: 13px;
    }
  }
`;

const OptionalSpan = styled.span`
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: ${palette.placeholder};
  margin: auto 0 auto 10px;
`;

const OtherGuideParagraph = styled.p`
  &&&& {
    margin-bottom: 64px;
  }
`;

const OtherGuideRowHolder = styled(Col)`
  min-width: 240px;
  .row:not(:last-child) {
    margin-bottom: 48px;
  }

  .row {
    .col:first-child {
      .row {
        padding-right: 24px;
      }
    }
    .col:last-child {
      .row {
        padding-left: 24px;
      }
    }
  }
`;

const OtherGuideRow = styled(Row)`
  && {
    min-height: 56px;

    a {
      color: ${palette.black};
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;

      p {
        color: ${palette.slate};
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
`;

const StyledSmallWidthSection = styled(SmallWidthSection)`
  background-color: white;
  width: auto;
  margin-left: -40px !important;
  margin-right: -40px !important;
  margin-bottom: -40px !imporatnt;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
`;

const OtherGuideLink = ({
  link,
  subtitle,
}: {
  link: { text: string; linkTo: string };
  subtitle: string;
}) => (
  <OtherGuideRow className="d-flex flex-column">
    <Link to={link.linkTo}>
      {link.text}&thinsp;
      <ChevronRight size={14} />
      <p className="mt-2">{subtitle}</p>
    </Link>
  </OtherGuideRow>
);

const LinkPage = () => {
  const categories = useCategories();

  return (
    <StaticFancyPageContainer
      leftSidebar={<GetStartedLeftSidebar />}
      rightSidebar={
        <GettingStartedStickyRightSidebar scrollHeightPercentage={90}>
          <AsideBarContent
            Icon={<Wand />}
            content={
              <span>
                <p>Want to start using Merge Link without writing any code?</p>
                <p>
                  View our <Link to="/guides/magic-link/">Magic Link</Link> guide to send end users
                  a URL to authorize their integrations!
                </p>
              </span>
            }
            title={"Magic Link"}
          />
        </GettingStartedStickyRightSidebar>
      }
    >
      <DocsHelmet
        title="Merge — Merge Link"
        description="Learn how to use Merge's features via our guided introductions."
      />

      <SmallWidthSection fadeBackground={false}>
        <NavigationScrollTracker>
          <StyledHeaderBar
            title="Merge Link"
            subtitle={
              <>
                <p>
                  <b>Merge Link</b> is a UI component that guides your app&apos;s user through
                  setting up an integration.
                </p>
                <div className="d-flex justify-center items-center flex-col py-6">
                  <div className="rounded-xl mb-5 shadow-md">
                    <img
                      src={gif}
                      alt="Preview of Merge Link component"
                      placeholder="none"
                      width={320}
                      className="rounded-xl"
                    />
                  </div>
                </div>
              </>
            }
            styling="top-level"
          />
        </NavigationScrollTracker>
      </SmallWidthSection>

      <SmallWidthSection fadeBackground={false} className="mt-9 pt-2">
        <StyledNavigationScrollTrackerWithAnchor
          title="Add Merge Link to your product"
          headingLevel="h3"
        />
        <p>
          Merge Link utilizes a series of token exchanges to securely authenticate your users&apos;
          integrations.
        </p>
        <p>In this guide, you&apos;ll set up the following in your application:</p>
        <StepOL>
          <li>
            Get a <code>link_token</code> to initialize a Merge Link session for your end user.
          </li>
          <li>Make Merge Link appear in your frontend.</li>
          <li>
            Swap for an <code>account_token</code>, which authenticates future requests to the
            Unified API.
          </li>
        </StepOL>
        <StaticImageContainer>
          <StaticImage
            src="../../assets/images/docs/get-started/link/link-diagram.png"
            alt={
              "Diagrams associated with each step. \
The diagram for step 1 depicts the following: Your frontend sends a POST request for a link_token to your backend. \
Then your backend sends a POST request to Merge's backend for the link_token. \
Your backend returns the POST request from Merge's backend to your frontend. \
The diagram for step 2 is an example image of the Merge Link component. \
The diagram for step 3 depicts the following: Your frontend sends the public_token received from the Merge Link component to your backend. \
Then your backend makes a request to Merge's backend with the public_token to retrieve the account_token."
            }
            quality={100}
            placeholder="none"
            layout="constrained"
            loading="eager"
          />
        </StaticImageContainer>
      </SmallWidthSection>

      <BackgroundBlockingSectionDivider />

      <TwoColumnSection
        columnA={
          <>
            <DeprecatedH6 className="text-uppercase text-secondary">Step 1</DeprecatedH6>
            <StyledNavigationScrollTrackerWithAnchor
              title="Backend – Get link token"
              anchorSlug="step-1"
              headingLevel="h3"
            />
            <p>
              In your backend, set up a POST request to initialize a Merge Link session and get a{" "}
              <code>link_token</code> from this URL:
            </p>
            <GetStartedURLBox>
              <code>https://api.merge.dev/api/integrations/create-link-token</code>
            </GetStartedURLBox>
            <p>
              Note: If you are using our SDKs, you can get a Link Token using a category-specific
              endpoint. For example, for HRIS, it can be found at{" "}
              <a href="https://docs.merge.dev/hris/link-token/" target="_blank">
                https://docs.merge.dev/hris/link-token
              </a>
              .
            </p>
            <p>
              Pass in your{" "}
              <a href="https://app.merge.dev/keys" target="_blank">
                Production Access API key
              </a>{" "}
              as a header; use a production access key to create a production Linked Account or a
              test access key to create a test Linked Account.
            </p>
            <DeprecatedH3>POST request body</DeprecatedH3>
            <p>Configure your Merge Link with the following parameters:</p>
            <div className="overflow-auto mt-6 mb-3">
              <Table>
                <thead>
                  <tr>
                    <td>Parameter</td>
                    <td>Type</td>
                    <td>Description</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <code>end_user_origin_id</code>
                    </td>
                    <td>String</td>
                    <td>
                      Unique ID for your end user. For more information see{" "}
                      <a
                        href={MERGE_END_USER_ORIGIN_ID_GUIDE}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        End user origin ID
                      </a>{" "}
                      guide.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>end_user_organization_name</code>
                    </td>
                    <td>String</td>
                    <td>Your end user&apos;s organization.</td>
                  </tr>
                  <tr>
                    <td>
                      <code>end_user_email_address</code>
                    </td>
                    <td>String</td>
                    <td>Your end user&apos;s email address.</td>
                  </tr>
                  <tr>
                    <td>
                      <code>categories</code>
                    </td>
                    <td>Array</td>
                    <td>
                      The integration categories to show in Merge Link.
                      <br />
                      <br />
                      <code>
                        [
                        {Object.keys(CategoryPrimaryModelInfo)
                          .map((category) => `"${category}"`)
                          .join(", ")}
                        ]
                      </code>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>integration</code>
                      <OptionalSpan>Optional</OptionalSpan>
                    </td>
                    <td>String</td>
                    <td>
                      Identifier of third-party platform to skip Merge Link menu for.
                      <br />
                      <br />
                      See{" "}
                      <a
                        href={LINKS.MERGE_LINK_SINGLE_INTEGRATION.linkTo}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        single integration
                      </a>{" "}
                      guide.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>link_expiry_mins</code>
                      <OptionalSpan>Optional</OptionalSpan>
                    </td>
                    <td>Integer</td>
                    <td>
                      An integer number of minutes between [30, 720 or 10080 if for a Magic Link
                      URL] for how long this token is valid. Defaults to 30.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>should_create_magic_link_url</code>
                      <OptionalSpan>Optional</OptionalSpan>
                    </td>
                    <td>Boolean</td>
                    <td>
                      Whether to generate a Magic Link URL. Defaults to false. For more information
                      on Magic Link, see{" "}
                      <a
                        href={LINKS.MERGE_MAGIC_LINK.linkTo}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Magic Link
                      </a>{" "}
                      guide.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <DeprecatedH3>API response</DeprecatedH3>
            <p>The response will include the following fields:</p>
            <div className="overflow-auto mt-6 mb-9">
              <Table>
                <thead>
                  <tr>
                    <td>Parameter</td>
                    <td>Type</td>
                    <td>Description</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <code>link_token</code>
                    </td>
                    <td>String</td>
                    <td>Temporary token to initialize your end user&apos;s Merge Link.</td>
                  </tr>
                  <tr>
                    <td>
                      <code>integration_name</code>
                    </td>
                    <td>String</td>
                    <td>
                      The name of any previously connected third-party platform (otherwise{" "}
                      <code>null</code>).
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>magic_link_url</code>
                    </td>
                    <td>String</td>
                    <td>
                      The URL of the magic link if specified (otherwise <code>null</code>).
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <p className="pt-6 mb-lg-6">
              Pass the <code>link_token</code> to your frontend to display Merge Link in step 2.
            </p>
          </>
        }
        columnB={
          <>
            <CodeExample
              folder="get-started/link/backend-generate-link-token"
              codeBlockName="Backend - Generate Link Token"
            />
            <YellowDiv>
              <DeprecatedH4>End User Origin ID</DeprecatedH4>
              <p>
                Each <code>end_user_origin_id</code> can have a maximum of{" "}
                <b>one Linked Account per category</b>.
              </p>
              <p className="end-user-origin-id-example-text">
                For example, each ID can have up to{" "}
                {(Object.keys(CategoryPrimaryModelInfo) as APICategory[])
                  .map(
                    (category, index, array) =>
                      `${index === array.length - 1 ? "and " : ""}one ${
                        categories[category].displayName
                      }`,
                  )
                  .join(", ")}{" "}
                integration simultaneously.
              </p>
              <p className="end-user-origin-id-example-text">
                If you want to link multiple accounts for the same user, learn more in our{" "}
                <a
                  href="https://help.merge.dev/en/articles/5389804-can-i-have-multiple-linked-accounts-per-customer-within-a-single-category"
                  target="_blank"
                  rel="noreferrer"
                >
                  help center
                </a>
                .
              </p>
            </YellowDiv>
          </>
        }
      />
      <BackgroundBlockingSectionDivider />
      <TwoColumnSection
        columnA={
          <>
            <DeprecatedH6 className="text-uppercase text-secondary">Step 2</DeprecatedH6>
            <StyledNavigationScrollTrackerWithAnchor
              title="Frontend – Make Merge Link appear"
              anchorSlug="step-2"
              headingLevel="h3"
            />
            <p>
              In your frontend, use the <code>link_token</code> from step 1 to open Merge Link.
            </p>
            <DeprecatedH3>Display Merge Link</DeprecatedH3>
            <p>Pass in these parameters:</p>
            <div className="overflow-auto mt-6 mb-9">
              <Table>
                <thead>
                  <tr>
                    <td>Parameter</td>
                    <td>Type</td>
                    <td>Description</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <code>linkToken</code>
                    </td>
                    <td>String</td>
                    <td>Initializing token from step 1.</td>
                  </tr>
                  <tr>
                    <td>
                      <code>onSuccess</code>
                    </td>
                    <td>Function</td>
                    <td>
                      Callback to handle <code>public_token</code>, which is returned when your end
                      user finishes their Merge Link session. Use it immediately to swap for your
                      account token.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>onExit</code>
                      <OptionalSpan>Optional</OptionalSpan>
                    </td>
                    <td>Function</td>
                    <td>
                      Callback to handle when your end user closes Merge Link. You can add your own
                      logic here to define any functionality.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>tenantConfig</code>
                      <OptionalSpan>Optional</OptionalSpan>
                    </td>
                    <td>Object</td>
                    <td>
                      Parameter to specify an <code>apiBaseURL</code> for a tenant. For example, for
                      the EU multi-tenant, <code>apiBaseURL</code> should be set to{" "}
                      <code>https://api-eu.merge.dev</code>. <br /> <br /> The default value is{" "}
                      <code>https://api.merge.dev</code>.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <p className="pt-6 mb-lg-6">
              Pass the <code>public_token</code> to your backend to securely swap it for an{" "}
              <code>account_token</code> in step 3.
            </p>
          </>
        }
        columnB={
          <>
            <CodeExample
              folder="get-started/link/frontend-initiate-link"
              codeBlockName="Frontend - Initiate Merge Link"
            />
          </>
        }
      />

      <BackgroundBlockingSectionDivider />

      <TwoColumnSection
        columnA={
          <>
            <DeprecatedH6 className="text-uppercase text-secondary">Step 3</DeprecatedH6>
            <StyledNavigationScrollTrackerWithAnchor
              title="Backend – Swap public token for account token"
              anchorSlug="step-3"
              headingLevel="h3"
            />
            <p>
              In your backend, create a request to exchange the short-lived{" "}
              <code>public_token</code> for a permanent <code>account_token</code>.
            </p>
            <StyledBasicNoticeCard
              feIconName="info"
              bodyElement={
                <p>
                  <b>Important:</b> Securely store this <code>account_token</code> in your database
                  for authenticating future API requests to the Unified API regarding the end
                  user&apos;s data.
                </p>
              }
            />
          </>
        }
        columnB={
          <>
            <CodeExample
              folder="get-started/link/backend-swap-for-account-token"
              codeBlockName="Backend - Swap for Account Token"
            />
          </>
        }
      />

      <BackgroundBlockingSectionDivider />

      <StyledSmallWidthSection fadeBackground={false}>
        <GetStartedOnboardingStep
          stepLabel="Finish line"
          stepTitle="Congrats on completing our onboarding guide!"
          stepContent={
            <>
              <OtherGuideParagraph>
                Discover other Merge features in our{" "}
                <Link to={LINKS.GUIDES.linkTo}>{LINKS.GUIDES.text}</Link> or learn more about
                Unified API functionality in the links below.
              </OtherGuideParagraph>
              <Row className="mx-0">
                <OtherGuideRowHolder>
                  <Row className="w-100">
                    <Col className="col-6">
                      <OtherGuideLink
                        link={LINKS.API_REFERENCE}
                        subtitle="Navigate our Unified API."
                      />
                    </Col>
                    <Col className="col-6">
                      <OtherGuideLink
                        link={LINKS.LINKED_ACCOUNTS}
                        subtitle="Linked Accounts are connections to third-party apps. Get available operations using this endpoint."
                      />
                    </Col>
                  </Row>
                  <Row className="w-100">
                    <Col className="col-6">
                      <OtherGuideLink
                        link={LINKS.SUPPLEMENTAL_DATA}
                        subtitle="Bypass Merge data normalization and utilize original data from third-party platforms."
                      />
                    </Col>
                    <Col className="col-6">
                      <OtherGuideLink link={LINKS.PAGINATION} subtitle="Paginate API list data." />
                    </Col>
                  </Row>
                  <Row className="w-100">
                    <Col className="col-6">
                      <OtherGuideLink
                        link={LINKS.WEBHOOKS}
                        subtitle="Get real-time events POSTed to you."
                      />
                    </Col>
                    <Col className="col-6">
                      <OtherGuideLink
                        link={LINKS.INTEGRATION_METADATA}
                        subtitle="Helpful for displaying integrations in your app."
                      />
                    </Col>
                  </Row>
                </OtherGuideRowHolder>
              </Row>
            </>
          }
        />
      </StyledSmallWidthSection>
    </StaticFancyPageContainer>
  );
};

export default LinkPage;
