import { APICategory, Badge } from "@merge-api/merge-javascript-shared";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

import { Integration, RouterLocation } from "../../../types/types";
import CrossGuideLinks from "../navigation/CrossGuideLinks";
import DocsHelmet from "../shared-components/DocsHelmet";
import AuthenticationSection from "./AuthenticationSection";
import SDKOverviewSection from "./SDKOverviewSection";
import SyncDataOverviewSection from "./SyncDataOverviewSection";
import CategoryAPIHelpOverviewSection from "./CategoryAPIHelpOverviewSection";
import UseCaseOverviewSection from "./UseCaseOverviewSection";
import {
  getCategoryReferenceName,
  abbreviationForAPICategory,
  displayNameForAPICategory,
  primaryModelForAPICategory,
} from "../utils/services";
import CopyWrapper from "../shared-components/CopyWrapper";
import { CopyIcon } from "lucide-react";
import { spectrum } from "styles/theme";
import { StaticFancyPageContainerWithTOC } from "../shared-components/StaticFancyPageContainer";
import { useWorkflows } from "hooks/useWorkflows";

import NavigationScrollTrackerWithAnchor from "../navigation/NavigationScrollTrackerWithAnchor";

interface OverviewSectionProps {
  category: string;
  location: RouterLocation;
}

interface AllIntegrations {
  allIntegrations: {
    nodes: Array<Integration>;
  };
}

const OverviewSection = ({ category, location }: OverviewSectionProps) => {
  const { allIntegrations } = useStaticQuery<AllIntegrations>(graphql`
    query {
      allIntegrations {
        nodes {
          id
          name
          slug
          categories
          omitLogo: omit_logo_from_landing_page
          image
          square: square_image
          color: color_image
          darkGray: dark_grey_image
          lightGray: light_grey_image
          white: white_image
          isAutoWebhookSetupEnabled: is_auto_webhook_setup_enabled
          isManualWebhookSetupEnabled: is_manual_webhook_setup_enabled
          webhookSetupGuideURL: webhook_setup_guide_url
        }
      }
    }
  `);
  const apiCategoryDisplayName = displayNameForAPICategory(category as APICategory);
  const apiCategoryFullDisplayName = getCategoryReferenceName(category as APICategory);
  const categoryAbbreviation = abbreviationForAPICategory(category as APICategory);
  const primaryModelForCategory = primaryModelForAPICategory(category as APICategory);
  const { renderedWorkflows } = useWorkflows(
    {
      value: categoryAbbreviation,
      label: categoryAbbreviation,
    },
    undefined,
    undefined,
  );
  const tableOfContents = [
    {
      text: "Base API URL",
      anchorSlug: "base-api-url",
    },
    {
      text: "Authentication",
      anchorSlug: "authentication",
      linkItems: [{ text: "Header parameters", anchorSlug: "header-parameters" }],
    },
    {
      text: "Merge SDKs",
      anchorSlug: "merge-sdks",
    },
    {
      text: "Syncing data",
      anchorSlug: "syncing-data",
      linkItems: [
        { text: "Query parameters", anchorSlug: "query-parameters" },
        { text: "Rate limits", anchorSlug: "rate-limits" },
        { text: "Sync frequency", anchorSlug: "sync-frequency" },
      ],
    },
    {
      text: "Testing via Postman",
      anchorSlug: "testing-via-postman_supported-integration-fields",
    },
    {
      text: "Supported integration fields",
      anchorSlug: "testing-via-postman_supported-integration-fields",
    },
  ];

  if (renderedWorkflows.length > 0) {
    tableOfContents.push({
      text: "Use cases",
      anchorSlug: "use-cases",
    });
  }
  return (
    <StaticFancyPageContainerWithTOC
      headerOffset={-110}
      style={{ paddingTop: 0 }}
      tableOfContents={tableOfContents}
    >
      <DocsHelmet
        title={`${apiCategoryFullDisplayName} Unified API reference - Merge Docs`}
        description={`Learn how to integrate with the Merge ${apiCategoryDisplayName} API.`}
      />
      <Col lg={9} id="overview" key="overview" className="docs--tag-section">
        <h2 className="d-flex align-items-center text-[26px] leading-[36px]">
          {`${apiCategoryFullDisplayName}`}
          {category === "mktg" ? (
            <Badge className="ml-2" size="lg" color="blue">
              Beta
            </Badge>
          ) : null}
        </h2>
        {`Welcome to the Merge ${apiCategoryFullDisplayName}`}
        {categoryAbbreviation !== "MKTG" && categoryAbbreviation !== "FILE"
          ? ` (${categoryAbbreviation})`
          : ""}
        {` API reference documentation!`} You can use this API to integrate with Merge and pull data
        from or push data to integrations that your users have authorized.
        <hr className="h-[1px] mt-0 mb-9"></hr>
        <NavigationScrollTrackerWithAnchor
          headingLevel="h4"
          title="Base API URL "
          anchorSlug="base-api-url"
          pageLoadOffsetInPx={-220}
          style={{ marginTop: 0, marginBottom: 12 }}
        >
          <p className="mb-4">
            All API endpoints in the reference documentation are relative to the following base URL:
          </p>
          <div className="bg-gray-0 pt-3 pb-3 pl-4 pr-4 rounded-lg">
            <div className="d-flex items-center  justify-between">
              <span className="text-[13px] font-mono">
                {`https://api.merge.dev/api/${category}/v1`}
              </span>
              <CopyWrapper
                text={`https://api.merge.dev/api/${category}/v1`}
                id={"merge-us-copy-base"}
                target={
                  <CopyIcon
                    size={16}
                    color={`${spectrum.gray70}`}
                    className="fe fe-copy bg-gray-10 cursor-pointer"
                  />
                }
              />
            </div>
          </div>
          <p className="mt-6 mb-4">
            If you are using our EU tenant, all API endpoints in the reference documentation are
            relative to the following base URL:
          </p>
          <div className="bg-gray-0 pt-3 pb-3 pl-4 pr-4 rounded-lg">
            <div className="d-flex items-center justify-between">
              <span className="text-[13px] font-mono">
                {`https://api-eu.merge.dev/api/${category}/v1`}
              </span>
              <CopyWrapper
                text={`https://api-eu.merge.dev/api/${category}/v1`}
                id={"merge-eu-copy-base"}
                target={
                  <CopyIcon
                    size={16}
                    color={`${spectrum.gray70}`}
                    className="fe fe-copy bg-gray-10 cursor-pointer"
                  />
                }
              />
            </div>
          </div>
          <p className="mt-6 mb-4">
            If you are using our APAC tenant, all API endpoints in the reference documentation are
            relative to the following base URL:
          </p>
          <div className="bg-gray-0 pt-3 pb-3 pl-4 pr-4 rounded-lg mb-9">
            <div className="d-flex items-center justify-between">
              <span className="text-[13px] font-mono">
                {`https://api-ap.merge.dev/api/${category}/v1`}
              </span>
              <CopyWrapper
                text={`https://api-ap.merge.dev/api/${category}/v1`}
                id={"merge-apac-copy-base"}
                target={
                  <CopyIcon
                    size={16}
                    color={`${spectrum.gray70}`}
                    className="fe fe-copy bg-gray-10 cursor-pointer"
                  />
                }
              />
            </div>
          </div>
        </NavigationScrollTrackerWithAnchor>
        <hr className="h-[1px] mt-0 mb-9"></hr>
        <div className="mb-9">
          <AuthenticationSection category={category} primaryModel={primaryModelForCategory} />
        </div>
        <hr className="h-[1px] mt-0 mb-9"></hr>
        <div className="mb-9">
          <SDKOverviewSection />
        </div>
        <hr className="h-[1px] mt-0 mb-9"></hr>
        <div className="mb-9">
          <SyncDataOverviewSection category={category} />
        </div>
        <hr className="h-[1px] mt-0 mb-9"></hr>
        <div className="mb-9">
          <CategoryAPIHelpOverviewSection category={category} />
        </div>
        {renderedWorkflows.length > 0 && (
          <>
            <hr className="h-[1px] mt-0 mb-9"></hr>

            <div className="mb-9">
              <UseCaseOverviewSection
                useCases={renderedWorkflows.slice(0, 3)}
                categoryName={apiCategoryDisplayName!}
              />
            </div>
          </>
        )}
        <CrossGuideLinks location={location} style={{ marginTop: "15px" }} />
      </Col>
    </StaticFancyPageContainerWithTOC>
  );
};

export default OverviewSection;
