import { HashContext } from "components/docs/shared-components/HashContext";
import { useCallback, useContext, useMemo } from "react";
import { RouterLocation } from "types/types";
import {
  PrimaryLink,
  QuaternaryLink,
  SecondaryLink,
  TertiaryLink,
  useDocsNavLinks,
} from "./useDocsNavLinks";

interface ActiveDocsLink {
  /**
   * Top level link that's active
   */
  primary: PrimaryLink;

  /**
   * If the page we're on has a secondary link, that'll be populated here
   */
  secondary: SecondaryLink | null;

  /**
   * If the page we're on has a tertiary link, that'll be populated here
   */
  tertiary: TertiaryLink | null;

  /**
   * If the page has a sub-nav for tertiary links and has a quaternary list
   * of links within it, this will be populated.
   */
  quaternary: QuaternaryLink | null;

  /**
   * This function takes a primary link and returns true if that link is
   * currently active based on what we've computed the active docs links to be.
   */
  isActive: (link: PrimaryLink) => boolean;

  /**
   * The current url with hashes added
   */
  currentUrl: string;
}

type AnyLink = PrimaryLink | SecondaryLink | TertiaryLink | QuaternaryLink;

/**
 * This represents a tuple of a link that has the most shared paths with another link, along
 * with a count of how many paths that is
 */
type MostSharedPathsResult<LinkType extends AnyLink> = { link: LinkType; totalSharedPaths: number };

/**
 * Takes a url and ensures that the link (whether or not it includes a hash)
 * ends with a trailing slash so we can make comparisons knowing that our
 * URLs will match if they're the same URL and differ only via trailing
 * slash.
 */
const ensureNoTrailingSlash = (urlString: string) => urlString.replace(/\/?(\?|#|$)/, "$1");

/**
 * Splits a string into segments, based on / and # as the delimiters.
 * Returns the array. Removes the primary `/docs` so we don't have an empty segment
 * that matches to start with.
 */
const segments = (value: string): Array<string> =>
  ensureNoTrailingSlash(value)
    .replace("/docs", "docs")
    .split("/")
    .map((segment) => segment.split("#"))
    .flat()
    .map((segment) => segment.replace(/_$/, ""));

/**
 * Assuming both strings are path representations that have segments separated
 * by slashes and/or hashes, calculates the maximum number of segments
 * they both have in common, starting from the left side.
 */
export const maxSharedDocPathSegments = (pathA: string, pathB: string): number => {
  const segmentsA = segments(pathA);
  const segmentsB = segments(pathB);
  let foundEndOfSharedPaths = false;
  return segmentsA.reduce((totalSharedPaths, segment, index) => {
    if (!foundEndOfSharedPaths && segment === segmentsB[index]) {
      return totalSharedPaths + 1;
    }
    foundEndOfSharedPaths = true;
    return totalSharedPaths;
  }, 0);
};

/**
 * Helper function to figure out which of the links we pass to this function have the most
 * paths in common with the `pathname` representing the current page. `level`
 * is used to determine the minimum necessary to mark the path as shared. For
 * a primary link, it's anything > 0, but as you progress farther on, the
 * links need to share more segments to make them "shared".
 */
const maxSharedPathsLink = <LinkType extends AnyLink>(
  currentUrl: string,
  links: Array<LinkType> | undefined,
  level: number,
): LinkType | null =>
  links?.reduce(
    (currentCandidate, link) => {
      // If a regex exists, use it to determine if the current url matches
      if (link?.pathnameMatchRegex?.test(currentUrl)) {
        return { link, totalSharedPaths: level + 1 };
      }

      // Otherwise, compare the number of shared doc path segments for # of shared paths
      const totalSharedPaths = maxSharedDocPathSegments(currentUrl, link.linkTo);
      if (
        totalSharedPaths > level &&
        (currentCandidate === null || totalSharedPaths > currentCandidate.totalSharedPaths)
      ) {
        return { link, totalSharedPaths };
      }
      return currentCandidate;
    },
    null as MostSharedPathsResult<LinkType> | null,
  )?.link ?? null;

/**
 * Determines if a given link is equal to another, given their characteristics - this
 * can be used to determine equality of a primary link with another object that has
 * an additional number of items in it.
 */
export const isEqual = (link: AnyLink, otherLink: AnyLink): boolean =>
  link.linkTo === otherLink.linkTo;

/**
 * This hook figures out which ID is the current top level id for the page we're on.
 * It does so by taking the top level nav links, splitting them into path segments, and
 * figuring out which is most shared (though API reference links are slightly different)
 */
export const useActiveDocsLink = (location: RouterLocation): ActiveDocsLink => {
  /**
   * Sometimes our quaternary links are updated via scrolling to a different section on
   * the page to update the hash - those changes are captured here and applied to the
   * active quaternary link.
   */
  const { hash } = useContext(HashContext);
  const docsNavLinks = useDocsNavLinks();
  const primaryLinks = useMemo(() => Object.values(docsNavLinks), [docsNavLinks]);
  const currentUrl = useMemo(
    () => (hash ? `${location.pathname}#${hash}` : location.pathname),
    [location.pathname, hash],
  );

  // Each level depends on the level above
  const primary = useMemo(
    () => maxSharedPathsLink(currentUrl, primaryLinks, 0) ?? docsNavLinks.HOME,
    [currentUrl, docsNavLinks.HOME, primaryLinks],
  );
  const secondary = useMemo(
    () => maxSharedPathsLink(currentUrl, primary.secondaryLinks, 1),
    [currentUrl, primary.secondaryLinks],
  );

  // Combine all links + sections into a list of links to search for tertiary links
  const tertiaryLinks = useMemo(
    () => secondary?.tertiarySections?.map((section) => section.tertiaryLinks).flat(),
    [secondary?.tertiarySections],
  );
  const tertiary = useMemo(
    () => maxSharedPathsLink<TertiaryLink>(currentUrl, tertiaryLinks, 2),
    [currentUrl, tertiaryLinks],
  );
  const quaternary = useMemo(
    () => maxSharedPathsLink(currentUrl, tertiary?.quaternaryLinks, 3),
    [currentUrl, tertiary?.quaternaryLinks],
  );

  const isActive = useCallback((link: PrimaryLink) => isEqual(link, primary), [primary]);

  return {
    primary,
    secondary,
    tertiary,
    quaternary,
    isActive,
    currentUrl,
  };
};
