import React, { useMemo } from "react";
import { Accordion } from "react-bootstrap";
import styled from "styled-components";
import { RouterLocation } from "types/types";
import { removeTrailingSlash } from "./navigationHelpers";
import TertiaryLinkSectionEntry from "./TertiaryLinkSectionEntry";
import { FULL_TOP_NAV_HEIGHT } from "./TopNavigationBar";
import { useActiveDocsLink } from "./useActiveDocsLink";

interface Props {
  location: RouterLocation;
}

const DesktopOnlySideNav = styled.nav`
  @media (max-width: 767.98px) {
    display: none !important;
  }
  position: fixed;
  height: calc(100vh - ${FULL_TOP_NAV_HEIGHT}px);
`;

const SideNavContainerDiv = styled.div<{ isEmpty: boolean }>`
  @media (max-width: 767.98px) {
    display: none !important;
  }
  position: relative;
  z-index: 9999;
  height: 100%;
  width: ${(props) => (props.isEmpty ? 0 : 310)}px;
  display: flex;
  justify-content: flex-start;
  flex-shrink: 0;
  padding: 24px 0 24px 0;
  gap: 0px;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 1px 0px 0px var(--gray10);
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  transition: width 0.5s;

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    box-shadow: 0.5px 0px 0px var(--gray20);
  }
`;

const SideNavPlaceholder = styled(SideNavContainerDiv)<{ isEmpty: boolean }>`
  height: calc(100vh - ${FULL_TOP_NAV_HEIGHT}px);
  z-index: -1;
`;

/**
 * Element that shows the tertiary and quaternary links for docs navigation. Has a
 * left-aligned sidebar, always visible, except on mobile. Shows tertiary links in sections
 * if the current page's data wants sections, and quaternary links as accordians under the
 * tertiary links as necessary.
 */
const LeftNavigationBar = ({ location }: Props) => {
  const { secondary: activeSecondaryLink } = useActiveDocsLink(location);
  const tertiarySections = useMemo(
    () => activeSecondaryLink?.tertiarySections,
    [activeSecondaryLink?.tertiarySections],
  );

  const leftNavIsEmpty = useMemo(
    () => tertiarySections === undefined || tertiarySections.length === 0,
    [tertiarySections],
  );

  return (
    <>
      <DesktopOnlySideNav>
        <SideNavContainerDiv isEmpty={leftNavIsEmpty}>
          <Accordion activeKey={removeTrailingSlash(location.pathname)}>
            {tertiarySections &&
              tertiarySections.map((section) => (
                <TertiaryLinkSectionEntry
                  key={section.header ?? "link-group-untitled"}
                  location={location}
                  {...section}
                />
              ))}
          </Accordion>
        </SideNavContainerDiv>
      </DesktopOnlySideNav>
      <SideNavPlaceholder isEmpty={leftNavIsEmpty} />
    </>
  );
};

export default LeftNavigationBar;
