import React from "react";
import styled from "styled-components";
import { Boxes, Rocket } from "lucide-react";
import { HTTPMethodMergeBadge } from "components/docs/shared-components/MergeBadges";
import { transformDocsSlugToTitle } from "components/docs/utils/DocumentationUtils";
import { UseCaseModelType, UseCaseFeatureType } from "types/UseCaseWorkflowTypes";
type UseCaseModelsAndFieldsProps = {
  useCaseModelsAndFields: UseCaseModelType[];
  useCaseFeatures: UseCaseFeatureType[];
};

const ListContainer = styled.div`
  gap: 4px;
`;

const UseCaseModelsAndFields = ({
  useCaseModelsAndFields,
  useCaseFeatures,
}: UseCaseModelsAndFieldsProps) => {
  const sortedUseCaseModelsAndFields = useCaseModelsAndFields.sort((a, b) => {
    return (b.method_fields?.fields?.length || 0) - (a.method_fields?.fields?.length || 0);
  });

  return (
    <div className="border border-gray-10 rounded-lg">
      <div className="pt-3 pl-4 pr-4 pb-4">
        <div className="d-flex align-items-center">
          <Boxes size={14} />
          <div className="ml-2.5 font-semibold text-md">Key models & fields</div>
        </div>
        <div className="mt-2">
          {sortedUseCaseModelsAndFields.map(
            (
              {
                method_fields,
                common_model_docs_slug,
                common_model_name,
                category,
              }: UseCaseModelType,
              index,
            ) => {
              const isLastItem = index === sortedUseCaseModelsAndFields.length - 1;
              return (
                <div className={isLastItem ? "" : "mb-1"}>
                  <div className="d-flex align-items-center mb-1">
                    <div className="flex-grow-1">
                      {common_model_docs_slug ? (
                        <a
                          href={`/${common_model_docs_slug}`}
                          target="_blank"
                          className="text-sm font-medium"
                        >
                          {transformDocsSlugToTitle(common_model_docs_slug)}
                        </a>
                      ) : (
                        <a href={`/${category}`} target="_blank" className="text-sm font-medium">
                          {common_model_name}
                        </a>
                      )}
                    </div>
                    <div className="align-self-end">
                      {method_fields.methods && (
                        <ListContainer className="d-flex">
                          {method_fields.methods.map((method: string) => {
                            return HTTPMethodMergeBadge({ method });
                          })}
                        </ListContainer>
                      )}
                    </div>
                  </div>
                  {method_fields.fields && (
                    <div className="pl-2.5 d-flex flex-column">
                      {method_fields.fields.map((field: string) => {
                        return (
                          <div className="font-mono leading-22 text-11 text-gray-60">{field}</div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            },
          )}
        </div>
      </div>
      {useCaseFeatures && useCaseFeatures.length > 0 && (
        <div className="border-t border-gray-0 pt-3 pl-4 pr-4 pb-4">
          <div className="d-flex align-items-center">
            <Rocket size={14} />
            <h6 className="mb-0 ml-2.5">Key features</h6>
          </div>
          <ListContainer className="d-flex flex-column mt-2 text-blue-40 text-sm">
            {useCaseFeatures.map((feature) => {
              return (
                <div>
                  <a href={feature.url} target="_blank">
                    {feature.name}
                  </a>
                </div>
              );
            })}
          </ListContainer>
        </div>
      )}
    </div>
  );
};

export default UseCaseModelsAndFields;
