import React, { useMemo } from "react";
import { APICategory } from "@merge-api/merge-javascript-shared";
import { Contact, HelpCircle, Ticket } from "lucide-react";
import styled from "styled-components";
import { featherIconForAPICategory } from "../utils/services";
import LINKS from "./links";
import type { PrimaryLink, SecondaryLink } from "./useDocsNavLinks";
import { useDocsNavLinks } from "./useDocsNavLinks";
import MarketingAutomationIcon from "../../../assets/svg/marketing-automation-feather.svg";
import { FileSearch } from "lucide-react";
import { FeatherIconSpan } from "./MobileNavigationBarStyledComponents";

interface WithIcon {
  /**
   * This is the name of a feather icon, starting with `fe-`, or an element
   */
  icon: string | React.ReactElement;
}
/**
 * These are normal primary links with the addition of an icon
 */
export type PrimaryLinkWithIcon = PrimaryLink & WithIcon;

/**
 * These are normal secondary links with the addition of an icon
 */
export type SecondaryLinkWithIcon = SecondaryLink & WithIcon;

/**
 * Shows a section for the mobile nav - some have titles, some have dividers after it.
 * The links here have icons and may contain sublinks, which trigger a dropdown when
 * tapped on.
 */
export interface MobileNavSection {
  header?: string;

  /**
   * The list of links to show as primary, all have icons, some may actually be
   * secondary links we're showing as primary on mobile since they're prominent
   */
  primaryLinks: Array<PrimaryLinkWithIcon | SecondaryLinkWithIcon>;

  hasDividerAfter?: true;
}

const StyledIcon = styled.div`
  margin: auto 14px;
`;

const MarketingAutomationIconStyled = styled(MarketingAutomationIcon)`
  margin: auto 14px;
`;

const createPrimaryLinkWithIconForCategory = (
  secondaryLink: SecondaryLink,
  category: APICategory,
): PrimaryLinkWithIcon => {
  if (
    category !== APICategory.ticketing &&
    category !== APICategory.crm &&
    category !== APICategory.mktg
  ) {
    return { ...secondaryLink, icon: `fe-${featherIconForAPICategory(category)}` };
  }
  if (category === APICategory.crm) {
    return { ...secondaryLink, icon: <StyledIcon as={Contact} size={15} /> };
  }
  if (category === APICategory.ticketing) {
    return { ...secondaryLink, icon: <StyledIcon as={Ticket} size={15} /> };
  }
  return { ...secondaryLink, icon: <MarketingAutomationIconStyled width={15} height={15} /> };
};

/**
 * A giant entry of links & sublinks for the mobile nav bar.
 * Reference only entries in `topNavLinks`! Hydrates the
 * entries with isActive status from the current location.
 *
 * Returns an entry for the links as a giant object
 */
const useDocsMobileNavLinks = (): Array<MobileNavSection> => {
  const allLinks = useDocsNavLinks();

  return useMemo(() => {
    const apiReferenceSection: MobileNavSection = {
      header: "API reference",
      primaryLinks:
        allLinks.API_REFERENCE.secondaryLinks?.map((apiSublink) => {
          switch (apiSublink.linkTo) {
            case LINKS.MERGE_API_BASICS.linkTo:
              return { ...apiSublink, icon: "fe-box" };
            case LINKS.SUPPLEMENTAL_DATA.linkTo:
              return { ...apiSublink, icon: "fe-database" };
            case LINKS.ATS_OVERVIEW.linkTo:
              return createPrimaryLinkWithIconForCategory(apiSublink, APICategory.ats);
            case LINKS.HRIS_OVERVIEW.linkTo:
              return createPrimaryLinkWithIconForCategory(apiSublink, APICategory.hris);
            case LINKS.ACCOUNTING_OVERVIEW.linkTo:
              return createPrimaryLinkWithIconForCategory(apiSublink, APICategory.accounting);
            case LINKS.TICKETING_OVERVIEW.linkTo:
              return createPrimaryLinkWithIconForCategory(apiSublink, APICategory.ticketing);
            case LINKS.CRM_OVERVIEW.linkTo:
              return createPrimaryLinkWithIconForCategory(apiSublink, APICategory.crm);
            case LINKS.MKTG_OVERVIEW.linkTo:
              return createPrimaryLinkWithIconForCategory(apiSublink, APICategory.mktg);
            case LINKS.FILESTORAGE_OVERVIEW.linkTo:
              return createPrimaryLinkWithIconForCategory(apiSublink, APICategory.filestorage);
            default:
              throw new TypeError(
                "Unexpected secondary link from API_REFERENCE - add to this switch if it is expected",
              );
          }
        }) ?? [],
      hasDividerAfter: true,
    };

    return [
      {
        primaryLinks: [{ ...allLinks.HOME, icon: "fe-home" }],
      },
      {
        header: "Learn",
        primaryLinks: [
          { ...allLinks.GET_STARTED, icon: "fe-cpu" },
          { ...allLinks.GUIDES, icon: "fe-book-open" },
          {
            ...allLinks.USE_CASES,
            icon: (
              <FeatherIconSpan>
                <FileSearch size={14} />
              </FeatherIconSpan>
            ),
          },
          { ...allLinks.HELP_CENTER, icon: "fe-help-circle" },
        ],
        hasDividerAfter: true,
      },
      apiReferenceSection,
      {
        header: "Integrations",
        primaryLinks:
          allLinks.ALL_INTEGRATIONS.secondaryLinks?.map((categorySublink) => {
            if (categorySublink.linkTo.includes(APICategory.hris)) {
              return createPrimaryLinkWithIconForCategory(categorySublink, APICategory.hris);
            }
            if (categorySublink.linkTo.includes(APICategory.ats)) {
              return createPrimaryLinkWithIconForCategory(categorySublink, APICategory.ats);
            }
            if (categorySublink.linkTo.includes(APICategory.accounting)) {
              return createPrimaryLinkWithIconForCategory(categorySublink, APICategory.accounting);
            }
            if (categorySublink.linkTo.includes(APICategory.ticketing)) {
              return createPrimaryLinkWithIconForCategory(categorySublink, APICategory.ticketing);
            }
            if (categorySublink.linkTo.includes(APICategory.crm)) {
              return createPrimaryLinkWithIconForCategory(categorySublink, APICategory.crm);
            }
            if (categorySublink.linkTo.includes(APICategory.mktg)) {
              return createPrimaryLinkWithIconForCategory(categorySublink, APICategory.mktg);
            }
            if (categorySublink.linkTo.includes(APICategory.filestorage)) {
              return createPrimaryLinkWithIconForCategory(categorySublink, APICategory.filestorage);
            }
            throw new TypeError(
              "Unexpected secondary link from ALL_INTEGRATIONS - add to this switch if it is expected",
            );
          }) ?? [],
      },
    ];
  }, [allLinks]);
};

export default useDocsMobileNavLinks;
