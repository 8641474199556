/**
 * Base URL for merge docs for <a> tags
 */
export const MERGE_DOCS_BASE_URL = `https://docs.merge.dev`;

/**
 * Base URL for Merge Link
 */
const MERGE_LINK_BASE_URL = `/guides/merge-link`;

/**
 * Base URL for Merge Writes
 */
const MERGE_WRITES_BASE_URL = `/guides/merge-writes`;

/**
 * Base URL for Writes troubleshooting
 */
const WRITES_TROUBLESHOOTING_BASE_URL = `/guides/writes-troubleshooting`;

/**
 * Base URL for Writes troubleshooting
 */
const FIELD_MAPPING_BASE_URL = `/supplemental-data/field-mappings`;

export const MERGE_HOME_PAGE = "https://www.merge.dev/";
/**
 * Base URL for Merge's Pricing page
 */
export const MERGE_PRICING_PAGE = "https://www.merge.dev/pricing";

export const MERGE_DASHBOARD = "https://app.merge.dev";

export const SCOPES_CONFIG_BLOG = "https://merge.dev/blog/introducing-model-and-field-level-scopes";

/**
 * Base URL for Merge's Pricing page
 */

export const MERGE_DEMO_PAGE = "https://merge.dev/get-in-touch";

/**
 * Base URL for Merge's Automatic Webhooks guides
 */

export const MERGE_DOCS_AUTOMATIC_WEBHOOKS_GUIDE = `/basics/webhooks/third-party-webhooks/`;

export const MERGE_AUTOMATIC_WEBHOOKS_GUIDE =
  "https://help.merge.dev/en/articles/7231268-automatic-manual-webhook-creation-by-integration";

/**
 * Base URL for Merge's Force Resync endpoint
 */

export const FORCE_RESYNC_ENDPOINT = `/hris/force-resync/#sync_status_resync_create`;

/**
 * Base URL for Merge Help Center Guides
 */
export const MERGE_END_USER_ORIGIN_ID_GUIDE =
  "https://help.merge.dev/en/articles/8032943-end-user-origin-id-best-practices";

/**
 * General export for our guides
 */
export const MERGE_WRITES_GUIDE_URL = `https://docs.merge.dev/guides/merge-writes`;

export const MERGE_CUSTOM_OBJECTS_GUIDE_URL = `https://docs.merge.dev/supplemental-data/custom-objects`;

/**
 * Merge Deletion Detection Guide
 */

export const MERGE_DELETION_DETECTION_GUIDE =
  "https://help.merge.dev/en/articles/5392795-deleted-data";

/**
 * Merge Webhooks page
 */

export const MERGE_DASHBOARD_WEBHOOK_EMITTER_PAGE =
  "https://app.merge.dev/configuration/webhooks/emitters";

/**
 * Supported Features and Automatic Webhook Detection
 */

export const MERGE_SUPPORTD_FEATURES_TABLE = `${MERGE_DOCS_BASE_URL}/integrations/hris/supported-features/`;

/**
 * A giant list of links we use across the site - all top level must have `text` & almost all have `linkTo`. They are
 * not specifically typed so this remains an object literal so we have a set list of keys
 * we can query for and get a compile time error if their values are missing/misspelled.
 *
 * Each linkTo URL must be a valid page in our docs side of things. Duplicates are allowed.
 * See `useDocsNavLinks` for most uses of this object and its values. When adding new links,
 * do add them here, but also add them to the `useDocsNavLinks` array if they should be put
 * into docs nav anywhere.
 */
const LINKS = {
  HOME: {
    text: "Home",
    linkTo: "/",
  },
  MERGE_LINK_SINGLE_INTEGRATION: {
    text: "Single integration",
    linkTo: `${MERGE_LINK_BASE_URL}/single-integration`,
  },
  MERGE_WRITES_OVERVIEW: {
    text: "Merge writes",
    linkTo: MERGE_WRITES_BASE_URL,
  },
  MERGE_WRITES_INTRO: {
    text: "Introduction",
    linkTo: `${MERGE_WRITES_BASE_URL}/writes`,
    crossLinksText: "Writes",
  },
  MERGE_WRITES_RELATED_NESTED: {
    text: "Nested",
    linkTo: `${MERGE_WRITES_BASE_URL}/writes/related-and-nested`,
    crossLinksText: "Related and nested writes",
  },
  MERGE_WRITES_PROGRAMMATIC_INTRO: {
    text: "Introduction",
    linkTo: `${MERGE_WRITES_BASE_URL}/programmatic`,
    crossLinksText: "Programmatic writes with /meta",
  },
  MERGE_WRITES_PROGRAMMATIC_NESTED: {
    text: "Nested",
    linkTo: `${MERGE_WRITES_BASE_URL}/programmatic/nested`,
    crossLinksText: "Programmatic nested writes with /meta",
  },
  MERGE_WRITES_PROGRAMMATIC_TEMPLATES_CONDITIONAL: {
    text: "Templates and conditional fields",
    linkTo: `${MERGE_WRITES_BASE_URL}/programmatic/templates-conditional`,
  },
  MERGE_WRITES_TROUBLESHOOTING: {
    text: "Troubleshooting writes",
    linkTo: WRITES_TROUBLESHOOTING_BASE_URL,
  },
  MERGE_WRITES_WARNINGS_ERRORS: {
    text: "Warnings and errors reference",
    linkTo: `${WRITES_TROUBLESHOOTING_BASE_URL}/warnings-errors`,
  },
  SDKS: {
    text: "SDKs",
    linkTo: "/sdk",
  },
  API_REFERENCE: {
    text: "API reference",
    linkTo: "/basics/authentication",
  },
  API_KEY_REFERENCE: {
    text: "API key reference",
    linkTo: "/basics/authentication#api-key",
  },
  ALL_INTEGRATIONS: {
    text: "Integrations",
  },
  GUIDES: {
    text: "Guides",
    linkTo: "/guides",
  },
  USE_CASES: {
    text: "Use cases",
    linkTo: "/use-cases",
  },
  GET_STARTED_INTRODUCTION: {
    text: "Introduction",
    linkTo: "/get-started/introduction",
  },
  GET_STARTED_UNIFIED_API: {
    text: "Unified API",
    linkTo: "/get-started/unified-api",
  },
  GET_STARTED_LINKED_ACCOUNT: {
    text: "Linked Account",
    linkTo: "/get-started/linked-account",
  },
  GET_STARTED_LINK: {
    text: "Merge Link",
    linkTo: "/get-started/link",
  },
  MERGE_API_BASICS: {
    text: "Merge API basics",
    linkTo: "/basics/authentication",
  },
  AUTHENTICATION: {
    text: "Authentication",
    linkTo: "/basics/authentication",
  },
  PAGINATION: {
    text: "Pagination",
    linkTo: "/basics/pagination",
  },
  WEBHOOKS: {
    text: "Webhooks",
    linkTo: "/basics/webhooks/overview",
  },
  WEBHOOKS_OVERVIEW: {
    text: "Overview",
    linkTo: "/basics/webhooks/overview",
  },
  WEBHOOKS_MERGE_TO_YOU: {
    text: "Merge webhooks",
    linkTo: "/basics/webhooks/merge-webhooks",
  },
  WEBHOOKS_THIRD_PARTIES_TO_MERGE: {
    text: "Third-party webhooks",
    linkTo: "/basics/webhooks/third-party-webhooks",
  },
  INTEGRATION_METADATA: {
    text: "Integration Metadata",
    linkTo: "/basics/integration-metadata",
  },
  ASYNC_OPERATIONS: {
    text: "Async operations",
    linkTo: "/basics/async-operations",
  },
  IDEMPOTENCY: {
    text: "Idempotency",
    linkTo: "/basics/idempotency",
  },
  LINKED_ACCOUNTS: {
    text: "Linked Accounts",
    linkTo: "/basics/linked-accounts",
  },
  DATA_SYNCS: {
    text: "Syncing data",
    linkTo: "/basics/syncing-data",
  },
  SYNC_FREQUENCY: {
    text: "Sync frequency",
    linkTo: "/basics/sync-frequency",
  },
  RATE_LIMITS: {
    text: "Rate limits",
    linkTo: "/basics/rate-limits",
  },
  VERSIONING: {
    text: "Versioning",
    linkTo: "/basics/versioning",
  },
  ATS_OVERVIEW: {
    text: "Overview",
    linkTo: "/ats/overview",
  },
  HRIS_OVERVIEW: {
    text: "Overview",
    linkTo: "/hris/overview",
  },
  ACCOUNTING_OVERVIEW: {
    text: "Overview",
    linkTo: "/accounting/overview",
  },
  TICKETING_OVERVIEW: {
    text: "Overview",
    linkTo: "/ticketing/overview",
  },
  CRM_OVERVIEW: {
    text: "Overview",
    linkTo: "/crm/overview",
  },
  MKTG_OVERVIEW: {
    text: "Overview",
    linkTo: "/mktg/overview",
  },
  FILESTORAGE_OVERVIEW: {
    text: "Overview",
    linkTo: "/filestorage/overview",
  },
  FILESTORAGE_FILEPICKER: {
    text: "File Picker",
    linkTo: "/filestorage/file-picker",
  },
  SUPPLEMENTAL_DATA: {
    text: "Supplemental Data",
    linkTo: "/supplemental-data/overview",
  },
  REMOTE_DATA: {
    text: "Remote Data",
    linkTo: "/supplemental-data/remote-data",
  },
  PASSTHROUGH_REQUEST_OVERVIEW: {
    text: "Overview",
    linkTo: "/supplemental-data/passthrough/overview",
  },
  PASSTHROUGH_REQUEST_MAKING_A_REQUEST: {
    text: "Passthrough Requests",
    linkTo: "/supplemental-data/passthrough/making-a-request",
  },
  PASSTHROUGH_REQUEST_ASYNC_PASSTHROUGH: {
    text: "Async Passthrough Requests",
    linkTo: "/supplemental-data/passthrough/async-passthrough",
  },
  SCHEMA_PROPERTIES: {
    text: "Schema properties",
    linkTo: "/guides/schema-properties",
  },
  MERGE_MAGIC_LINK: {
    text: "Magic Link",
    linkTo: "/guides/magic-link",
  },
  MERGE_FIELD_MAPPING_OVERVIEW: {
    text: "Overview",
    linkTo: `${FIELD_MAPPING_BASE_URL}/overview`,
  },
  MERGE_FIELD_MAPPING_TARGET_FIELDS: {
    text: "Target fields",
    linkTo: `${FIELD_MAPPING_BASE_URL}/target-fields`,
  },
  MERGE_FIELD_MAPPING_OVERVIEW_VIDEO: {
    text: "Overview video",
    linkTo: `${FIELD_MAPPING_BASE_URL}/overview-video`,
  },
  MERGE_FIELD_MAPPING_CREATE_MAPPING: {
    text: "Create a Field Mapping",
    linkTo: `${FIELD_MAPPING_BASE_URL}/create-field-mapping/overview`,
  },
  MERGE_FIELD_MAPPING_CREATE_MAPPING_OVERVIEW: {
    text: "Overview",
    linkTo: `${FIELD_MAPPING_BASE_URL}/create-field-mapping/overview`,
  },
  MERGE_FIELD_MAPPING_CREATE_MAPPING_ACROSS_INTEGRATION: {
    text: "Mapping across an integration",
    linkTo: `${FIELD_MAPPING_BASE_URL}/create-field-mapping/across-an-integration`,
  },
  MERGE_FIELD_MAPPING_CREATE_MAPPING_FOR_LINKED_ACCOUNT: {
    text: "Mapping for a Linked Account",
    linkTo: `${FIELD_MAPPING_BASE_URL}/create-field-mapping/for-linked-account`,
  },
  MERGE_FIELD_MAPPING_ACCESS_MAPPED_DATA: {
    text: "Access mapped data",
    linkTo: `${FIELD_MAPPING_BASE_URL}/access-mapped-data`,
  },
  CUSTOM_OBJECTS: {
    text: "Custom Objects",
    linkTo: "/supplemental-data/custom-objects",
  },
  REMOTE_FIELDS_OVERVIEW: {
    text: "Remote Field classes",
    linkTo: "/supplemental-data/remote-fields/overview",
  },
  REMOTE_FIELDS_PULLING_DATA: {
    text: "Pulling data",
    linkTo: "/supplemental-data/remote-fields/pulling-data",
  },
  REMOTE_FIELDS_PUSHING_DATA: {
    text: "Pushing data",
    linkTo: "/supplemental-data/remote-fields/pushing-data",
  },
  INTEGRATIONS_HRIS_SUPPORTED_FIELDS: {
    text: "Supported fields",
    linkTo: "/integrations/hris/supported-fields",
  },
  INTEGRATIONS_ATS_SUPPORTED_FIELDS: {
    text: "Supported fields ",
    linkTo: "/integrations/ats/supported-fields",
  },
  INTEGRATIONS_ACCOUNTING_SUPPORTED_FIELDS: {
    text: "Supported fields",
    linkTo: "/integrations/accounting/supported-fields",
  },
  INTEGRATIONS_TICKETING_SUPPORTED_FIELDS: {
    text: "Supported fields",
    linkTo: "/integrations/ticketing/supported-fields",
  },
  INTEGRATIONS_CRM_SUPPORTED_FIELDS: {
    text: "Supported fields",
    linkTo: "/integrations/crm/supported-fields",
  },
  INTEGRATIONS_MKTG_SUPPORTED_FIELDS: {
    text: "Supported fields",
    linkTo: "/integrations/mktg/supported-fields",
  },
  INTEGRATIONS_FILESTORAGE_SUPPORTED_FIELDS: {
    text: "Supported fields",
    linkTo: "/integrations/filestorage/supported-fields",
  },
  INTEGRATIONS_HRIS_SUPPORTED_FEATURES: {
    text: "Supported features",
    linkTo: "/integrations/hris/supported-features",
  },
  INTEGRATIONS_ATS_SUPPORTED_FEATURES: {
    text: "Supported features ",
    linkTo: "/integrations/ats/supported-features",
  },
  INTEGRATIONS_ACCOUNTING_SUPPORTED_FEATURES: {
    text: "Supported features",
    linkTo: "/integrations/accounting/supported-features",
  },
  INTEGRATIONS_TICKETING_SUPPORTED_FEATURES: {
    text: "Supported features",
    linkTo: "/integrations/ticketing/supported-features",
  },
  INTEGRATIONS_CRM_SUPPORTED_FEATURES: {
    text: "Supported features",
    linkTo: "/integrations/crm/supported-features",
  },
  INTEGRATIONS_MKTG_SUPPORTED_FEATURES: {
    text: "Supported features",
    linkTo: "/integrations/mktg/supported-features",
  },
  INTEGRATIONS_FILESTORAGE_SUPPORTED_FEATURES: {
    text: "Supported features",
    linkTo: "/integrations/filestorage/supported-features",
  },
  INTEGRATIONS_HRIS_OVERVIEW: {
    text: "View all integrations",
    linkTo: "/integrations/hris/overview",
  },
  INTEGRATIONS_ATS_OVERVIEW: {
    text: "View all integrations",
    linkTo: "/integrations/ats/overview",
  },
  INTEGRATIONS_ACCOUNTING_OVERVIEW: {
    text: "View all integrations",
    linkTo: "/integrations/accounting/overview",
  },
  INTEGRATIONS_TICKETING_OVERVIEW: {
    text: "View all integrations",
    linkTo: "/integrations/ticketing/overview",
  },
  INTEGRATIONS_CRM_OVERVIEW: {
    text: "View all integrations",
    linkTo: "/integrations/crm/overview",
  },
  INTEGRATIONS_MKTG_OVERVIEW: {
    text: "View all integrations",
    linkTo: "/integrations/mktg/overview",
  },
  INTEGRATIONS_FILESTORAGE_OVERVIEW: {
    text: "View all integrations",
    linkTo: "/integrations/filestorage/overview",
  },
  INTEGRATIONS_HRIS_SANDBOXES: {
    text: "Sandboxes",
    linkTo: "/integrations/hris/sandboxes",
  },
  INTEGRATIONS_ATS_SANDBOXES: {
    text: "Sandboxes",
    linkTo: "/integrations/ats/sandboxes",
  },
  INTEGRATIONS_ACCOUNTING_SANDBOXES: {
    text: "Sandboxes",
    linkTo: "/integrations/accounting/sandboxes",
  },
  INTEGRATIONS_TICKETING_SANDBOXES: {
    text: "Sandboxes",
    linkTo: "/integrations/ticketing/sandboxes",
  },
  INTEGRATIONS_CRM_SANDBOXES: {
    text: "Sandboxes",
    linkTo: "/integrations/crm/sandboxes",
  },
  INTEGRATIONS_MKTG_SANDBOXES: {
    text: "Sandboxes",
    linkTo: "/integrations/mktg/sandboxes",
  },
  INTEGRATIONS_FILESTORAGE_SANDBOXES: {
    text: "Sandboxes",
    linkTo: "/integrations/filestorage/sandboxes",
  },
  HELP_CENTER: {
    text: "Help Center",
    linkTo: "https://help.merge.dev/",
  },
  SDK_HELP_CENTER: {
    text: "SDK Help Center",
    linkTo: "https://help.merge.dev/en/collections/4258952-sdks",
  },
  CHANGELOG: {
    text: "Changelog",
    linkTo: "https://www.merge.dev/changelog",
  },
} as const;

/**
 * These are an array of links for docs guides for reference elsewhere
 */
export const GUIDES_LINKS = {
  MERGE_MAGIC_LINK: {
    title: LINKS.MERGE_MAGIC_LINK.text,
    subtitle: "Integrate your end users without any frontend code",
    to: LINKS.MERGE_MAGIC_LINK.linkTo,
  },
  WRITES_OVERVIEW: {
    title: "Merge writes",
    subtitle: "Write data to third-party platforms",
    to: LINKS.MERGE_WRITES_OVERVIEW.linkTo,
  },
  WRITES_INTRO: {
    title: "Merge writes",
    subtitle: "Make POST requests to Merge to write data to third-party platforms",
    to: LINKS.MERGE_WRITES_INTRO.linkTo,
  },
  WRITES_RELATED_NESTED: {
    title: "Related and nested writes",
    subtitle:
      "Write data to third-party platforms with relations to existing and new Common Model instances",
    to: LINKS.MERGE_WRITES_RELATED_NESTED.linkTo,
  },
  WRITES_PROGRAMMATIC_INTRO: {
    title: "Programmatic writes with /meta",
    subtitle:
      "Use /meta to programmatically form and validate POST requests to write standardized or integration / Linked Account-specific model fields",
    to: LINKS.MERGE_WRITES_PROGRAMMATIC_INTRO.linkTo,
  },
  WRITES_PROGRAMMATIC_NESTED: {
    title: "Programmatic nested writes with /meta",
    subtitle:
      "Use /meta to create related Common Model instances (through nesting) with standardized or integration / Linked Account-specific fields",
    to: LINKS.MERGE_WRITES_PROGRAMMATIC_NESTED.linkTo,
  },
  WRITES_PROGRAMMATIC_TEMPLATES_CONDITIONAL: {
    title: "Templates and conditional fields",
    subtitle:
      "Use /meta to determine which specific model fields are available based on your user’s input in another field",
    to: LINKS.MERGE_WRITES_PROGRAMMATIC_TEMPLATES_CONDITIONAL.linkTo,
  },
  SCHEMA_PROPERTIES: {
    title: LINKS.SCHEMA_PROPERTIES.text,
    subtitle: "High-level overview of JSON schemas returned from Merge",
    to: LINKS.SCHEMA_PROPERTIES.linkTo,
  },
  TROUBLESHOOTING_WRITES: {
    title: LINKS.MERGE_WRITES_TROUBLESHOOTING.text,
    subtitle: "Learn about the tools used to troubleshoot POST requests",
    to: LINKS.MERGE_WRITES_TROUBLESHOOTING.linkTo,
  },
  FIELD_MAPPING_OVERVIEW: {
    title: LINKS.MERGE_FIELD_MAPPING_OVERVIEW.text,
    subtitle:
      "Map custom fields and other non-mapped data from your users' platforms to target fields on your Merge Common Models.",
    to: LINKS.MERGE_FIELD_MAPPING_OVERVIEW.linkTo,
  },
  FIELD_MAPPING_TARGET_FIELDS: {
    title: LINKS.MERGE_FIELD_MAPPING_TARGET_FIELDS.text,
    subtitle:
      "Target fields are fields on your Merge Common Models that your users' third-party fields are mapped to.",
    to: LINKS.MERGE_FIELD_MAPPING_TARGET_FIELDS.linkTo,
  },
  MERGE_FIELD_MAPPING_OVERVIEW_VIDEO: {
    title: LINKS.MERGE_FIELD_MAPPING_OVERVIEW_VIDEO.text,
    subtitle:
      "Watch this quick video guide to learn the basics of how Merge’s Field Mapping works.",
    to: LINKS.MERGE_FIELD_MAPPING_OVERVIEW_VIDEO.linkTo,
  },
  MERGE_FIELD_MAPPING_CREATE_MAPPING: {
    text: LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING.text,
    subtitle:
      "Merge gives you flexibility in how origin fields and targets are constructed, depending on your use case and intended user experience.",
    to: LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING.linkTo,
  },
  MERGE_FIELD_MAPPING_CREATE_MAPPING_OVERVIEW: {
    text: LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_OVERVIEW.text,
    to: LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_OVERVIEW.linkTo,
  },
  MERGE_FIELD_MAPPING_CREATE_MAPPING_ACROSS_INTEGRATION: {
    text: LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_ACROSS_INTEGRATION.text,
    to: LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_ACROSS_INTEGRATION.linkTo,
  },
  MERGE_FIELD_MAPPING_CREATE_MAPPING_FOR_LINKED_ACCOUNT: {
    text: LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_FOR_LINKED_ACCOUNT.text,
    to: LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_FOR_LINKED_ACCOUNT.linkTo,
  },
  MERGE_FIELD_MAPPING_ACCESS_MAPPED_DATA: {
    text: LINKS.MERGE_FIELD_MAPPING_ACCESS_MAPPED_DATA.text,
    subtitle: "Access Field Mappings in your Merge Dashboard and through Merge's Unified API.",
    to: LINKS.MERGE_FIELD_MAPPING_ACCESS_MAPPED_DATA.linkTo,
  },
} as const;

export default LINKS;
