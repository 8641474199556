import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { RouterLocation } from "types/types";
import DocumentationSection from "components/docs/DocumentationSection";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { MERGE_PRICING_PAGE } from "components/docs/navigation/links";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { PricingInfoCard } from "components/docs/shared-components/NoticeCards";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";

interface Props {
  location: RouterLocation;
}

const PassthroughOverviewPage = ({ location }: Props) => {
  const title = "Authenticated Passthrough Request";
  const description =
    "Learn how to create a new authenticated request to the integration API using stored linked account credentials.";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description}>
        <PricingInfoCard className="mb-9 mt-0">
          This feature is only available to customers on our <b>Professional</b> or{" "}
          <b>Enterprise</b> plans. View the{" "}
          <a href={MERGE_PRICING_PAGE} target="_blank" rel="noreferrer">
            {" "}
            Merge Plans
          </a>{" "}
          to learn more.
        </PricingInfoCard>
      </HeaderBar>
      <DocumentationSection title="Overview">
        <>
          <p>
            Using Merge&apos;s authenticated Passthrough Request, you can make requests directly to
            an integration&apos;s API.
          </p>
          <p>
            Merge makes it easy to make API requests to any of Merge&apos;s supported integrations
            with the account tokens that you&apos;ve stored on behalf of your users. These requests
            are sent directly to the integration&apos;s API and follow each integration&apos;s
            specific API formats instead of Merge&apos;s unified format. This approach is ideal for
            when you&apos;re looking to fetch data that Merge may not make requests for.
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <StaticImage
              src="../../../assets/images/illustrations/passthrough-request.png"
              alt="How Authenticated Passthrough Requests Work"
              quality={100}
              placeholder="none"
              layout="constrained"
              width={780}
            />
          </div>
          <p>
            If you believe Merge already pulls this data in its standard queries, you can avoid an
            additional API request to the integration platform by querying for{" "}
            <b>
              <Link to="/supplemental-data/remote-data">Remote Data</Link>
            </b>{" "}
            instead.
          </p>
        </>
      </DocumentationSection>
      <CrossGuideLinks location={location} />
    </StaticPageContainer>
  );
};

export default PassthroughOverviewPage;
