import {
  APICategory,
  doesPathItemObjectHaveTag,
  getIgnoreModelOperations,
  getOperationTags,
} from "@merge-api/merge-javascript-shared";
import useCategorySchemas from "components/docs/hooks/useCategorySchemas";
import { pageUrl } from "helpers/endpointMappings";
import type { UseEndpointMappingsType } from "hooks/useEndpointMappings";
import useEndpointMappings from "hooks/useEndpointMappings";
import type { OpenAPIV3 } from "openapi-types";
import { useMemo } from "react";
import { HTTPMethod } from "types/types";
import { partition } from "helpers/partition";
import { toTitleCase } from "../utils/DocumentationUtils";
import { getNavLinkNameForCategory, getAdditionalEndpointInfo } from "../utils/services";
import LINKS from "./links";
import { DeprecationStage } from "../assets/constants";

/**
 * Main interface for all levels of links
 */
export interface Link {
  /**
   * Display text for the link
   */
  text: string;

  /**
   * Destination for the link, relative to site base (so, should be `/...`)
   */
  linkTo: string;

  /**
   * When we're looking at which link is active, we default to shared path segments to
   * determine which is active. For example, if the page's `pathname` is `/example/three`
   * and we have a primary link with a `linkTo` of `/example`, then we'd say that primary
   * link is active, and a primary link with `/other-page` is not active.
   *
   * However, there are sometimes pages that don't follow a strict tree structure. Specifically,
   * the API reference pages, among others. To help those cases, this optional regexp can
   * help categorize misc pages under other links. It will be used with `<regexp>.text(pathname)`
   * for the current location's pathname. Only include where the page structure is not
   * strictly a tree structure, default behavior is fine in most cases!
   */
  pathnameMatchRegex?: RegExp;

  /**
   * Sometimes we have a slightly different name for the cross links that appear at
   * the bottom of a Guides page to lead to other pages. Example being Merge Writes,
   * as without this there's a page that has "Nested" both before and after the
   * programmatic writes page, but those are two different guides and need a different
   * name as a result.
   */
  crossLinksText?: string;
}

/**
 * Top level link, shows in main nav and mobile nav
 */
export type PrimaryLink = Link & {
  /**
   * If specified, contains secondary links that show up categorized under the top level links
   */
  secondaryLinks?: Array<SecondaryLink>;

  /**
   * Some primary links have links nested underneath, but we don't need to show secondary level
   * links since they're linked to via other types of UI (the Get started left sidebar). If this
   * flag is true, the secondary links aren't visually shown in a bar.
   */
  hidesSecondaryLinks?: boolean;
};

/**
 * Link that shows under a primary link, either in an accordion or as a bar under the main nav
 */
export type SecondaryLink = Link & {
  /**
   * If specified, contains tertiary link sections that show up categorized into a sidebar. Each
   * entry in this array shows a list of links, with an optional header.
   */
  tertiarySections?: Array<TertiaryLinkSection>;
};

/**
 * A header and list of links that show up underneath it. Header is optional
 */
export interface TertiaryLinkSection {
  /**
   * Title of the section
   */
  header?: string;

  /**
   * These show up as a list directly under the header and may contain the last level of links.
   */
  tertiaryLinks: Array<TertiaryLink>;

  /**
   * Regex to conditionally render a sidebar links section depending on the active path.
   */
  pathnameRenderRegex?: RegExp;

  /**
   * If these links should display on mobile. Only used for hacky supplemental data pages. Will be refactored out soon hopefully once redesigns come in.
   */
  hideOnMobile?: boolean;
}

/**
 * These are plain links, but may also contain even more links nested under the tertiary one.
 */
export type TertiaryLink = Link & {
  /**
   * This is the LAST level - shows up in the sidebar as subheadings under the tertiary links
   */
  quaternaryLinks?: Array<QuaternaryLink>;

  /**
   * If present, adds an icon to the left of the text in the sidebar
   */
  imageSrc?: string;

  /**
   * If this is true and the tertiary link has quaternary links, it
   * doesn't render as an accordion. Instead, it always appears
   * "expanded". By default, this value is false, and this tertiary
   * link with 4th level links renders as an accordion.
   */
  isAlwaysExpanded?: boolean;
};

/**
 * Plain links, but some have decorations (basically, which HTTPMethod it corresponds to)
 */
export type QuaternaryLink = Link & {
  /**
   * Which method this link corresponds to
   */
  method?: HTTPMethod;
};

interface SchemasByCategory {
  [APICategory.accounting]: OpenAPIV3.Document;
  [APICategory.ats]: OpenAPIV3.Document;
  [APICategory.hris]: OpenAPIV3.Document;
  [APICategory.ticketing]: OpenAPIV3.Document;
  [APICategory.crm]: OpenAPIV3.Document;
  [APICategory.mktg]: OpenAPIV3.Document;
  [APICategory.filestorage]: OpenAPIV3.Document;
}

/**
 * These are all the top level links we support as primary right now.
 */
type DocsNavLinks = {
  HOME: PrimaryLink;
  GET_STARTED: PrimaryLink;
  API_REFERENCE: PrimaryLink;
  ALL_INTEGRATIONS: PrimaryLink;
  GUIDES: PrimaryLink;
  HELP_CENTER: PrimaryLink;
  USE_CASES: PrimaryLink;
};

/**
 * Miscellaneous endpoints separarted into three different categories; Linked Account, Data Management, Org Management
 */

const LINKED_ACCOUNT_MISC_ENDPOINTS_ORDER = [
  "account-details",
  "account-token",
  "delete-account",
  "issues",
  "linked-accounts",
  "link-token",
  "field-mapping",
];

const DATA_MANAGEMENT_MISC_ENDPOINTS_ORDER = [
  "scopes",
  "force-resync",
  "passthrough",
  "async-passthrough",
  "sync-status",
  "async-tasks",
];

const HIDDEN_ENDPOINTS = ["webhook-receivers", "available-actions"];

const ORG_MANAGEMENT_MISC_ENDPOINTS_ORDER = ["generate-key", "regenerate-key", "audit-trail"];
// @akatipally add common model scopes back post refactor https://app.asana.com/0/1202262938305017/1204650827368490
export const MISC_LINK_ENDPOINTS = [
  ...LINKED_ACCOUNT_MISC_ENDPOINTS_ORDER,
  ...DATA_MANAGEMENT_MISC_ENDPOINTS_ORDER,
  ...ORG_MANAGEMENT_MISC_ENDPOINTS_ORDER,
];

type CustomNavTagsSchemaFilteringType = {
  header: string;
  tags: Array<string>;
};

/**
 * This will be used to filter out specific tags and render them in a section beneath the Common Models section in docs
 * (currently only used in the CRM docs for Custom )
 */
const CUSTOM_NAV_TAGS_FILTERING: Record<APICategory, Array<CustomNavTagsSchemaFilteringType>> = {
  [APICategory.accounting]: [],
  [APICategory.ats]: [],
  [APICategory.hris]: [],
  [APICategory.ticketing]: [],
  [APICategory.crm]: [
    {
      header: "Custom Objects",
      // Order matters for these tags - this will affect the order they display in
      tags: ["custom-object-classes", "custom-objects", "association-types", "associations"],
    },
  ],
  [APICategory.mktg]: [],
  [APICategory.filestorage]: [],
};

/**
 * Mapping from HTTPMethod to OpenAPIV3's HttpMethods as an array of entries, casted
 * since otherwise we can't conver the lowercase method to an HttpMethods properly.
 */
const SUPPORTED_HTTP_METHODS: Array<[HTTPMethod, OpenAPIV3.HttpMethods]> = [
  HTTPMethod.GET,
  HTTPMethod.POST,
  HTTPMethod.PATCH,
  HTTPMethod.PUT,
  HTTPMethod.DELETE,
  HTTPMethod.OPTIONS,
  HTTPMethod.HEAD,
].map((method) => [method, method.toLowerCase()]) as Array<[HTTPMethod, OpenAPIV3.HttpMethods]>;

/**
 * Returns a link for a common model tag + category combo. Uses `paths` from the category schema
 * to parse the tag into path links.
 */
const commonModelLink = (
  category: APICategory,
  tag: string,
  paths: OpenAPIV3.PathsObject,
): TertiaryLink => {
  const pathLinks = Object.entries(paths)
    .filter(
      ([_, pathItemObject]) => pathItemObject && doesPathItemObjectHaveTag(pathItemObject, tag),
    )
    .map(([path, pathItemObject]) =>
      SUPPORTED_HTTP_METHODS.filter(
        ([_, openApiMethod]) => pathItemObject?.[openApiMethod] !== undefined,
      ).map(([httpMethod, openApiMethod]) => {
        const operationId = pathItemObject?.[openApiMethod]?.operationId;
        const pathWithHash = `/${category}/${tag}#${operationId}`;

        return {
          text: path,
          linkTo: pathWithHash,
          method: httpMethod,
        };
      }),
    )
    .flat();

  return {
    text: toTitleCase(tag.split("-").join(" ")),
    linkTo: `/${category}/${tag}`,
    quaternaryLinks: [...pathLinks],
  };
};

/**
 * Creates a common models tertiary section for a particular API category
 */
const commonModelsTertiaryLinkSection = (
  category: APICategory,
  schemasByCategory: SchemasByCategory,
): TertiaryLinkSection => {
  const schema = schemasByCategory[category];

  const allCustomFilteredTags: Array<string> = [];
  CUSTOM_NAV_TAGS_FILTERING[category].forEach((customFilter) => {
    allCustomFilteredTags.push(...customFilter.tags);
  });

  // Grab the non-misc tags for the category and map them into links
  const commonModelTags = getOperationTags(schema)
    .filter((tag) => !MISC_LINK_ENDPOINTS.includes(tag))
    .filter((tag) => !HIDDEN_ENDPOINTS.includes(tag))
    .filter((tag) => !allCustomFilteredTags.includes(tag))
    .filter(
      (tag) =>
        getAdditionalEndpointInfo(tag, category)?.deprecationStage !== DeprecationStage.SUNSET,
    )
    .sort();

  // partition tags into active and deprecated so that deprecated tags are on bottom
  const [supportedTags, deprecatedTags] = partition(
    commonModelTags,
    (tag) =>
      getAdditionalEndpointInfo(tag, category)?.deprecationStage !== DeprecationStage.DEPRECATED,
  );

  const tertiaryLinks = [...supportedTags, ...deprecatedTags].map((tag: string) =>
    commonModelLink(category, tag, schema.paths),
  );

  return {
    header: "Common Models",
    tertiaryLinks,
  };
};

/**
 * Creates custom common models tertiary sections for a particular API category
 */
const customCommonModelsTertiaryLinkSection = (
  category: APICategory,
  schemasByCategory: SchemasByCategory,
): Array<TertiaryLinkSection> => {
  const schema = schemasByCategory[category];

  return CUSTOM_NAV_TAGS_FILTERING[category].map((customFilter) => {
    const tertiaryLinks = customFilter.tags.map((tag: string) =>
      commonModelLink(category, tag, schema.paths),
    );

    return {
      header: customFilter.header,
      tertiaryLinks,
    };
  });
};

/**
 * Creates a Linked Account specific endpoint tertiary section for a particular API category
 */
const miscLinkedAccountLinkSection = (category: APICategory): TertiaryLinkSection => {
  const tertiaryLinks = LINKED_ACCOUNT_MISC_ENDPOINTS_ORDER.map((tag: string) => ({
    text: toTitleCase(tag.split("-").join(" ")),
    linkTo: `/${category}/${tag}`,
  }));

  return {
    header: "Linked Account",
    tertiaryLinks,
  };
};

/**
 * Creates a Data Management specific endpoint tertiary section for a particular API category
 */

const miscDataManagementLinkSection = (
  category: APICategory,
  schemasByCategory: SchemasByCategory,
): TertiaryLinkSection => {
  let FILTERED_DATA_MANAGEMENT_MISC_ENDPOINTS_ORDER = DATA_MANAGEMENT_MISC_ENDPOINTS_ORDER;
  if (category != APICategory.accounting) {
    FILTERED_DATA_MANAGEMENT_MISC_ENDPOINTS_ORDER =
      FILTERED_DATA_MANAGEMENT_MISC_ENDPOINTS_ORDER.filter((value) => value != "async-tasks");
  }
  const tertiaryLinks = FILTERED_DATA_MANAGEMENT_MISC_ENDPOINTS_ORDER.map((tag: string) => ({
    text: toTitleCase(tag.split("-").join(" ")),
    linkTo: `/${category}/${tag}`,
  }));

  const ignoreOperations = getIgnoreModelOperations(schemasByCategory[category]);
  if (ignoreOperations.length > 0) {
    // TODO: for now, we link to the first ignore operation we find. But ideally we have a full page
    // that lists all of the ignore commands in the category.
    tertiaryLinks.push({
      text: "Ignore Model",
      linkTo: `/${category}/${ignoreOperations[0]?.tags?.[0]}#${ignoreOperations[0]?.operationId}`,
    });
  }

  return {
    header: "Data Management",
    tertiaryLinks,
  };
};

/**
 * Creates a Org Management specific endpoint tertiary section for a particular API category
 */

const miscOrgManagementLinkSection = (category: APICategory): TertiaryLinkSection => {
  const tertiaryLinks = ORG_MANAGEMENT_MISC_ENDPOINTS_ORDER.map((tag: string) => ({
    text: toTitleCase(tag.split("-").join(" ")),
    linkTo: `/${category}/${tag}`,
  }));

  return {
    header: "Org Management",
    tertiaryLinks,
  };
};

const useMiscLinkedAccountLinkSection = (): Record<APICategory, TertiaryLinkSection> =>
  useMemo(
    () => ({
      ats: miscLinkedAccountLinkSection(APICategory.ats),
      hris: miscLinkedAccountLinkSection(APICategory.hris),
      accounting: miscLinkedAccountLinkSection(APICategory.accounting),
      ticketing: miscLinkedAccountLinkSection(APICategory.ticketing),
      crm: miscLinkedAccountLinkSection(APICategory.crm),
      mktg: miscLinkedAccountLinkSection(APICategory.mktg),
      filestorage: miscLinkedAccountLinkSection(APICategory.filestorage),
    }),
    [],
  );

const useMiscDataManagementLinkSection = (): Record<APICategory, TertiaryLinkSection> => {
  const schemasByCategory = useCategorySchemas();

  return useMemo(
    () => ({
      ats: miscDataManagementLinkSection(APICategory.ats, schemasByCategory),
      hris: miscDataManagementLinkSection(APICategory.hris, schemasByCategory),
      accounting: miscDataManagementLinkSection(APICategory.accounting, schemasByCategory),
      ticketing: miscDataManagementLinkSection(APICategory.ticketing, schemasByCategory),
      crm: miscDataManagementLinkSection(APICategory.crm, schemasByCategory),
      mktg: miscDataManagementLinkSection(APICategory.mktg, schemasByCategory),
      filestorage: miscDataManagementLinkSection(APICategory.filestorage, schemasByCategory),
    }),
    [schemasByCategory],
  );
};

const useMiscOrgManagementLinkSection = (): Record<APICategory, TertiaryLinkSection> =>
  useMemo(
    () => ({
      ats: miscOrgManagementLinkSection(APICategory.ats),
      hris: miscOrgManagementLinkSection(APICategory.hris),
      accounting: miscOrgManagementLinkSection(APICategory.accounting),
      ticketing: miscOrgManagementLinkSection(APICategory.ticketing),
      crm: miscOrgManagementLinkSection(APICategory.crm),
      mktg: miscOrgManagementLinkSection(APICategory.mktg),
      filestorage: miscOrgManagementLinkSection(APICategory.filestorage),
    }),
    [],
  );

/**
 * Takes our full endpoint mappings and a category and returns tertiary links for that
 * category, showing each integration as a link to `/integrations/[category]/[integration.slug]`
 */

const endpointMappingsTertiaryLinkSection = (
  category: APICategory,
  endpointMappings: UseEndpointMappingsType,
) => {
  const integrationTertiaryLinks = Object.values(endpointMappings[category]).map(
    ({ integration }) => ({
      text: integration.abbreviated_name || integration.name,
      linkTo: pageUrl(category, integration, null),
      imageSrc: integration.square_image,
    }),
  );

  return integrationTertiaryLinks;
};

/**
 * Returns the common model links for all API categories
 */
export const useCommonModelLinks = (): Record<APICategory, TertiaryLinkSection> => {
  const schemasByCategory = useCategorySchemas();
  return useMemo(
    () => ({
      ats: commonModelsTertiaryLinkSection(APICategory.ats, schemasByCategory),
      hris: commonModelsTertiaryLinkSection(APICategory.hris, schemasByCategory),
      accounting: commonModelsTertiaryLinkSection(APICategory.accounting, schemasByCategory),
      ticketing: commonModelsTertiaryLinkSection(APICategory.ticketing, schemasByCategory),
      crm: commonModelsTertiaryLinkSection(APICategory.crm, schemasByCategory),
      mktg: commonModelsTertiaryLinkSection(APICategory.mktg, schemasByCategory),
      filestorage: commonModelsTertiaryLinkSection(APICategory.filestorage, schemasByCategory),
    }),
    [schemasByCategory],
  );
};

/**
 * Returns the custom common model links for all API categories
 */
export const useCustomCommonModelLinks = (): Record<APICategory, Array<TertiaryLinkSection>> => {
  const schemasByCategory = useCategorySchemas();
  return useMemo(
    () => ({
      ats: customCommonModelsTertiaryLinkSection(APICategory.ats, schemasByCategory),
      hris: customCommonModelsTertiaryLinkSection(APICategory.hris, schemasByCategory),
      accounting: customCommonModelsTertiaryLinkSection(APICategory.accounting, schemasByCategory),
      ticketing: customCommonModelsTertiaryLinkSection(APICategory.ticketing, schemasByCategory),
      crm: customCommonModelsTertiaryLinkSection(APICategory.crm, schemasByCategory),
      mktg: customCommonModelsTertiaryLinkSection(APICategory.mktg, schemasByCategory),
      filestorage: customCommonModelsTertiaryLinkSection(
        APICategory.filestorage,
        schemasByCategory,
      ),
    }),
    [schemasByCategory],
  );
};

/**
 * This controls our nav bar for mobile & desktop for all of docs, and the footer of
 * docs uses it to show a subset of our links as well. It maps top level ids to primary
 * links, which have secondary and tertiary links possible nested within them. Use the
 * `useActiveDocsLink` hook to determine which is currently showing - that'll return
 * the primary, secondary, and tertiary link that's active if available.
 *
 * This hook won't change after creation, but it's in a hook to allow us to use other
 * hooks when creating the secondary/tertiary links.
 */
export const useDocsNavLinks = (): Readonly<DocsNavLinks> => {
  const commonModelLinks = useCommonModelLinks();
  const customCommonModelLinks = useCustomCommonModelLinks();
  const categoryLinkedAccountLinks = useMiscLinkedAccountLinkSection();
  const categoryDataManagementLinks = useMiscDataManagementLinkSection();
  const categoryKeyManagementLinks = useMiscOrgManagementLinkSection();
  const endpointMappings = useEndpointMappings();

  /**
   * API reference is a huge section - it contains some of our guides links as well
   * as each category's common models and misc sections, and then supplemental data.
   * The pages aren't all under a common prefix, so we use the regex here.
   */
  const apiReferencePrimaryLink: PrimaryLink = useMemo(
    () => ({
      ...LINKS.API_REFERENCE,
      pathnameMatchRegex:
        /\/(?:basics|supplemental-data|ats|hris|accounting|crm|ticketing|mktg|filestorage).*/,
      secondaryLinks: [
        {
          ...LINKS.MERGE_API_BASICS,
          tertiarySections: [
            {
              tertiaryLinks: [
                LINKS.AUTHENTICATION,
                LINKS.PAGINATION,
                {
                  ...LINKS.WEBHOOKS,
                  isAlwaysExpanded: true,
                  quaternaryLinks: [
                    LINKS.WEBHOOKS_OVERVIEW,
                    LINKS.WEBHOOKS_MERGE_TO_YOU,
                    LINKS.WEBHOOKS_THIRD_PARTIES_TO_MERGE,
                  ],
                },
                LINKS.INTEGRATION_METADATA,
                LINKS.LINKED_ACCOUNTS,
                LINKS.DATA_SYNCS,
                LINKS.SYNC_FREQUENCY,
                LINKS.RATE_LIMITS,
                LINKS.VERSIONING,
                LINKS.ASYNC_OPERATIONS,
                LINKS.IDEMPOTENCY,
              ],
            },
          ],
        },
        {
          ...LINKS.HRIS_OVERVIEW,
          text: "HR, Payroll, and Directory (HRIS)",
          tertiarySections: [
            {
              tertiaryLinks: [LINKS.HRIS_OVERVIEW],
            },
            commonModelLinks.hris,
            ...customCommonModelLinks.hris,
            categoryLinkedAccountLinks.hris,
            categoryDataManagementLinks.hris,
            categoryKeyManagementLinks.hris,
          ],
        },
        {
          ...LINKS.ATS_OVERVIEW,
          text: "Recruiting (ATS)",
          tertiarySections: [
            {
              tertiaryLinks: [LINKS.ATS_OVERVIEW],
            },
            commonModelLinks.ats,
            ...customCommonModelLinks.ats,
            categoryLinkedAccountLinks.ats,
            categoryDataManagementLinks.ats,
            categoryKeyManagementLinks.ats,
          ],
        },
        {
          ...LINKS.ACCOUNTING_OVERVIEW,
          text: "Accounting",
          tertiarySections: [
            {
              tertiaryLinks: [LINKS.ACCOUNTING_OVERVIEW],
            },
            commonModelLinks.accounting,
            ...customCommonModelLinks.accounting,
            categoryLinkedAccountLinks.accounting,
            categoryDataManagementLinks.accounting,
            categoryKeyManagementLinks.accounting,
          ],
        },
        {
          ...LINKS.TICKETING_OVERVIEW,
          text: "Ticketing",
          tertiarySections: [
            {
              tertiaryLinks: [LINKS.TICKETING_OVERVIEW],
            },
            commonModelLinks.ticketing,
            ...customCommonModelLinks.ticketing,
            categoryLinkedAccountLinks.ticketing,
            categoryDataManagementLinks.ticketing,
            categoryKeyManagementLinks.ticketing,
          ],
        },
        {
          ...LINKS.CRM_OVERVIEW,
          text: "CRM",
          tertiarySections: [
            {
              tertiaryLinks: [LINKS.CRM_OVERVIEW],
            },
            commonModelLinks.crm,
            ...customCommonModelLinks.crm,
            categoryLinkedAccountLinks.crm,
            categoryDataManagementLinks.crm,
            categoryKeyManagementLinks.crm,
          ],
        },
        {
          ...LINKS.MKTG_OVERVIEW,
          text: "Marketing Automation",
          tertiarySections: [
            {
              tertiaryLinks: [LINKS.MKTG_OVERVIEW],
            },
            commonModelLinks.mktg,
            ...customCommonModelLinks.mktg,
            categoryLinkedAccountLinks.mktg,
            categoryDataManagementLinks.mktg,
            categoryKeyManagementLinks.mktg,
          ],
        },
        {
          ...LINKS.FILESTORAGE_OVERVIEW,
          text: "File Storage",
          tertiarySections: [
            {
              tertiaryLinks: [LINKS.FILESTORAGE_OVERVIEW, LINKS.FILESTORAGE_FILEPICKER],
            },
            commonModelLinks.filestorage,
            ...customCommonModelLinks.filestorage,
            categoryLinkedAccountLinks.filestorage,
            categoryDataManagementLinks.filestorage,
            categoryKeyManagementLinks.filestorage,
          ],
        },
        {
          ...LINKS.SUPPLEMENTAL_DATA,
          tertiarySections: [
            {
              pathnameRenderRegex:
                /\/supplemental-data\/(?!passthrough|field-mappings|remote-fields)([^/]+)(\/|$)/,
              tertiaryLinks: [
                {
                  ...LINKS.SUPPLEMENTAL_DATA,
                  text: "Overview",
                },
                LINKS.REMOTE_DATA,
                { ...LINKS.PASSTHROUGH_REQUEST_OVERVIEW, text: "Passthrough Request" },
                { ...LINKS.REMOTE_FIELDS_OVERVIEW, text: "Remote Field Classes" },
                { ...LINKS.MERGE_FIELD_MAPPING_OVERVIEW, text: "Field Mapping" },
                LINKS.CUSTOM_OBJECTS,
              ],
            },
            {
              pathnameRenderRegex: /\/supplemental-data\/passthrough(\/|$)/,
              header: "Passthrough Request",
              hideOnMobile: true,
              tertiaryLinks: [
                LINKS.PASSTHROUGH_REQUEST_OVERVIEW,
                LINKS.PASSTHROUGH_REQUEST_MAKING_A_REQUEST,
                LINKS.PASSTHROUGH_REQUEST_ASYNC_PASSTHROUGH,
              ],
            },
            {
              pathnameRenderRegex: /\/supplemental-data\/field-mappings(\/|$)/,
              header: "Field Mapping",
              hideOnMobile: true,
              tertiaryLinks: [
                LINKS.MERGE_FIELD_MAPPING_OVERVIEW,
                LINKS.MERGE_FIELD_MAPPING_OVERVIEW_VIDEO,
                LINKS.MERGE_FIELD_MAPPING_TARGET_FIELDS,
                {
                  ...LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING,
                  text: "Create a Field Mapping",
                  isAlwaysExpanded: true,
                  quaternaryLinks: [
                    LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_OVERVIEW,
                    LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_ACROSS_INTEGRATION,
                    LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_FOR_LINKED_ACCOUNT,
                  ],
                },
                { ...LINKS.MERGE_FIELD_MAPPING_ACCESS_MAPPED_DATA, text: "Access mapped data" },
              ],
            },
            {
              pathnameRenderRegex: /\/supplemental-data\/remote-fields(\/|$)/,
              header: "Remote Field classes",
              hideOnMobile: true,
              tertiaryLinks: [
                LINKS.REMOTE_FIELDS_OVERVIEW,
                LINKS.REMOTE_FIELDS_PULLING_DATA,
                LINKS.REMOTE_FIELDS_PUSHING_DATA,
              ],
            },
          ],
        },
      ],
    }),
    [
      commonModelLinks.hris,
      commonModelLinks.ats,
      commonModelLinks.accounting,
      commonModelLinks.ticketing,
      commonModelLinks.crm,
      commonModelLinks.mktg,
      commonModelLinks.filestorage,
      customCommonModelLinks.hris,
      customCommonModelLinks.ats,
      customCommonModelLinks.accounting,
      customCommonModelLinks.ticketing,
      customCommonModelLinks.crm,
      customCommonModelLinks.mktg,
      customCommonModelLinks.filestorage,
      categoryLinkedAccountLinks.hris,
      categoryLinkedAccountLinks.ats,
      categoryLinkedAccountLinks.accounting,
      categoryLinkedAccountLinks.ticketing,
      categoryLinkedAccountLinks.crm,
      categoryLinkedAccountLinks.mktg,
      categoryLinkedAccountLinks.filestorage,
      categoryDataManagementLinks.hris,
      categoryDataManagementLinks.ats,
      categoryDataManagementLinks.accounting,
      categoryDataManagementLinks.ticketing,
      categoryDataManagementLinks.crm,
      categoryDataManagementLinks.mktg,
      categoryDataManagementLinks.filestorage,
      categoryKeyManagementLinks.hris,
      categoryKeyManagementLinks.ats,
      categoryKeyManagementLinks.accounting,
      categoryKeyManagementLinks.ticketing,
      categoryKeyManagementLinks.crm,
      categoryKeyManagementLinks.mktg,
      categoryKeyManagementLinks.filestorage,
    ],
  );

  const sdksPrimaryLink: PrimaryLink = useMemo(
    () => ({
      ...LINKS.SDKS,
    }),
    [],
  );

  const getIntegrationCategoryOverviewPage = (category: string) => {
    switch (category) {
      case "hris":
        return [
          LINKS.INTEGRATIONS_HRIS_SUPPORTED_FIELDS,
          LINKS.INTEGRATIONS_HRIS_SUPPORTED_FEATURES,
          LINKS.INTEGRATIONS_HRIS_OVERVIEW,
          LINKS.INTEGRATIONS_HRIS_SANDBOXES,
        ];
      case "ats":
        return [
          LINKS.INTEGRATIONS_ATS_SUPPORTED_FIELDS,
          LINKS.INTEGRATIONS_ATS_SUPPORTED_FEATURES,
          LINKS.INTEGRATIONS_ATS_OVERVIEW,
          LINKS.INTEGRATIONS_ATS_SANDBOXES,
        ];
      case "accounting":
        return [
          LINKS.INTEGRATIONS_ACCOUNTING_SUPPORTED_FIELDS,
          LINKS.INTEGRATIONS_ACCOUNTING_SUPPORTED_FEATURES,
          LINKS.INTEGRATIONS_ACCOUNTING_OVERVIEW,
          LINKS.INTEGRATIONS_ACCOUNTING_SANDBOXES,
        ];
      case "ticketing":
        return [
          LINKS.INTEGRATIONS_TICKETING_SUPPORTED_FIELDS,
          LINKS.INTEGRATIONS_TICKETING_SUPPORTED_FEATURES,
          LINKS.INTEGRATIONS_TICKETING_OVERVIEW,
          LINKS.INTEGRATIONS_TICKETING_SANDBOXES,
        ];
      case "crm":
        return [
          LINKS.INTEGRATIONS_CRM_SUPPORTED_FIELDS,
          LINKS.INTEGRATIONS_CRM_SUPPORTED_FEATURES,
          LINKS.INTEGRATIONS_CRM_OVERVIEW,
          LINKS.INTEGRATIONS_CRM_SANDBOXES,
        ];
      case "mktg":
        return [
          LINKS.INTEGRATIONS_MKTG_SUPPORTED_FIELDS,
          LINKS.INTEGRATIONS_MKTG_SUPPORTED_FEATURES,
          LINKS.INTEGRATIONS_MKTG_OVERVIEW,
          LINKS.INTEGRATIONS_MKTG_SANDBOXES,
        ];
      case "filestorage":
        return [
          LINKS.INTEGRATIONS_FILESTORAGE_SUPPORTED_FIELDS,
          LINKS.INTEGRATIONS_FILESTORAGE_SUPPORTED_FEATURES,
          LINKS.INTEGRATIONS_FILESTORAGE_OVERVIEW,
          LINKS.INTEGRATIONS_FILESTORAGE_SANDBOXES,
        ];
      default:
        throw new Error("invalid category");
    }
  };
  /**
   * Shows all integrations, with categories as secondary links and the
   * actual integrations as tertiary links. There's no overview page, so just
   * pick the first integration of ATS as our primary link. We assume each
   * category has at least one integration in it!
   */
  const endpointMappingsPrimaryLink: PrimaryLink = useMemo(() => {
    // Creates the link structure for a category
    const makeLinkStructure = (category: APICategory) => {
      const integrationCategoryOverviewPage = getIntegrationCategoryOverviewPage(category);
      const endpointTertiaryLinks = [
        integrationCategoryOverviewPage[0],
        integrationCategoryOverviewPage[1],
        integrationCategoryOverviewPage[2],
        integrationCategoryOverviewPage[3],
        ...endpointMappingsTertiaryLinkSection(category, endpointMappings),
      ];
      return {
        text: getNavLinkNameForCategory(category) || category,
        linkTo: integrationCategoryOverviewPage[0]?.linkTo || "",
        tertiarySections: [{ tertiaryLinks: endpointTertiaryLinks }],
      };
    };

    const secondaryLinks = Object.values(APICategory).map(makeLinkStructure);

    return {
      ...LINKS.ALL_INTEGRATIONS,
      linkTo: secondaryLinks[0].linkTo,
      secondaryLinks,
    };
  }, [endpointMappings]);

  const getStartedPrimarylink: PrimaryLink = useMemo(
    () => ({
      ...LINKS.GET_STARTED_INTRODUCTION,
      text: "Get started",
      hidesSecondaryLinks: true,
      secondaryLinks: [
        { ...LINKS.GET_STARTED_INTRODUCTION, text: "Introduction" },
        LINKS.GET_STARTED_UNIFIED_API,
        LINKS.GET_STARTED_LINKED_ACCOUNT,
        LINKS.GET_STARTED_LINK,
      ],
    }),
    [],
  );

  /**
   * Guides has hidden secondary links, but some of those links have tertiary links. Specifically,
   * Merge Writes.
   */
  const guidesPrimaryLink: PrimaryLink = useMemo(() => {
    const mergeWrites: SecondaryLink = {
      ...LINKS.MERGE_WRITES_OVERVIEW,
      tertiarySections: [
        {
          header: "Merge Writes",
          tertiaryLinks: [
            { ...LINKS.MERGE_WRITES_OVERVIEW, text: "Overview" },
            {
              ...LINKS.MERGE_WRITES_INTRO,
              text: "Writes",
              isAlwaysExpanded: true,
              quaternaryLinks: [LINKS.MERGE_WRITES_INTRO, LINKS.MERGE_WRITES_RELATED_NESTED],
            },
            {
              ...LINKS.MERGE_WRITES_PROGRAMMATIC_INTRO,
              text: "Programmatic Writes with /meta",
              isAlwaysExpanded: true,
              quaternaryLinks: [
                LINKS.MERGE_WRITES_PROGRAMMATIC_INTRO,
                LINKS.MERGE_WRITES_PROGRAMMATIC_NESTED,
                LINKS.MERGE_WRITES_PROGRAMMATIC_TEMPLATES_CONDITIONAL,
              ],
            },
          ],
        },
      ],
    };

    const troubleshootingWrites: SecondaryLink = {
      ...LINKS.MERGE_WRITES_TROUBLESHOOTING,
      tertiarySections: [
        {
          header: "Writes troubleshooting",
          tertiaryLinks: [LINKS.MERGE_WRITES_TROUBLESHOOTING, LINKS.MERGE_WRITES_WARNINGS_ERRORS],
        },
      ],
    };

    return {
      ...LINKS.GUIDES,
      pathnameMatchRegex: /^\/guides*/,
      hidesSecondaryLinks: true,
      secondaryLinks: [
        { ...LINKS.GUIDES, text: "Overview" },
        LINKS.MERGE_LINK_SINGLE_INTEGRATION,
        mergeWrites,
        troubleshootingWrites,
      ],
    };
  }, []);

  return useMemo(
    () =>
      Object.freeze({
        HOME: LINKS.HOME,
        GET_STARTED: getStartedPrimarylink,
        GUIDES: guidesPrimaryLink,
        USE_CASES: LINKS.USE_CASES,
        API_REFERENCE: apiReferencePrimaryLink,
        SDKS: sdksPrimaryLink,
        ALL_INTEGRATIONS: endpointMappingsPrimaryLink,
        HELP_CENTER: LINKS.HELP_CENTER,
      }),
    [
      getStartedPrimarylink,
      guidesPrimaryLink,
      apiReferencePrimaryLink,
      sdksPrimaryLink,
      endpointMappingsPrimaryLink,
    ],
  );
};
