export const formatTextForIntegration = (text: string, color: string) => {
  if (!text) {
    return null;
  }
  const textAfterBolding = text.replace(
    /\*([^\][]+)\*/g,
    `<span style="color: ${color}">$1</span>`,
  );
  const textAfterNewlines = textAfterBolding.replace("\\n", "<br />");
  return textAfterNewlines;
};

export const hexToRGB = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const createSlug = (title: string) =>
  title
    .toLowerCase()
    .replace(/\?/g, "")
    .replace(/[:()[\]]/g, "")
    .replace(/\s/g, "-");
